import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';

export default class Helper {
  static possible = 'abcdefghijklmnopqrstuvwxyz1234567890';

  static GetRandom(lengthOfCode: number) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += this.possible.charAt(Math.floor(Math.random() * this.possible.length));
    }
    return text;
  }

  static RenameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  static CreateErrorMessage(messageService: NzMessageService, message: string) {
    messageService.error(message);
  }

  static CreateSuccessMessage(messageService: NzMessageService, message: string) {
    messageService.success(message);
  }

  static createBasicNotification(notification: NzNotificationService, title: string, text: string): void {
    notification.blank(title, text);
  }
}
