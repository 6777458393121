import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { switchMap, take, filter, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/auth/authentication.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.auth.getRefreshedToken().pipe(
              switchMap(() => {
                this.refreshTokenInProgress = false;
                return next.handle(this.injectToken(req));
              })
            );
          } else {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap((res) => {
                return next.handle(this.injectToken(req));
              })
            );
          }
        } else {
          return next.handle(req);
        }
      })
    );
  }

  injectToken(request: HttpRequest<any>) {
    const token = this.auth.getCurrentTokenValue.access_token;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
