import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-wind-direction',
  templateUrl: './wind.direction.component.html',
  styleUrls: ['./wind.direction.component.css'],
})
export class WindDirectionComponent implements OnInit {
  shortName: string;
  direction: number;
  @ViewChild('arrowDirection', { static: true }) arrowDirection: ElementRef;

  @Input()
  set windDirection(val: number) {
    if (val) {
      this.direction = val;
      this.renderer.setStyle(this.arrowDirection.nativeElement, 'transform', 'rotate(' + val + 'deg)');
      const directions = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW',
        'N',
      ];
      this.shortName = directions[Math.round((val % 360) / 22.5)];
    }
  }
  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  inRange(x: number, min: number, max: number) {
    return (x - min) * (x - max) <= 0;
  }
}
