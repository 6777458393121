import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Helper from 'src/app/_helpers/helper';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  randomString: string;
  sha256RandomString: string;
  base64UrlEncode: string;
  state: string;
  errorMessage: any;

  constructor(private message: NzMessageService) {}

  ngOnInit() {
    // Generate data for Auth
    this.randomString = Helper.GetRandom(56);
    this.pkceChallenge(this.randomString).then((base64urlencoded) => (this.base64UrlEncode = base64urlencoded));
    this.pkceChallenge(Helper.GetRandom(32)).then((base64urlencoded) => (this.state = base64urlencoded));
    this.errorMessage = localStorage.getItem('error');
    if (this.errorMessage !== null) {
      this.createMessage(this.errorMessage);
      localStorage.clear();
    }
  }

  createMessage(message: string): void {
    this.message.create('error', message);
  }

  goToLogin() {
    localStorage.setItem('cv', this.randomString);
    localStorage.setItem('state', this.state);
    window.location.href = this.createLoginUrl();
  }

  // Create login url for redirect
  createLoginUrl(): string {
    return (
      environment.authoriseUri +
      'response_type=' +
      environment.responseType +
      '&client_id=' +
      environment.clientId +
      '&scope=' +
      environment.scope +
      '&redirect_uri=' +
      window.location.origin +
      environment.authoriseResposeUri +
      '&code_challenge_method=' +
      environment.codeChanllengeMethod +
      '&code_challenge=' +
      this.base64UrlEncode +
      '&state=' +
      this.state
    );
  }

  async pkceChallenge(val: string) {
    return this.base64urlencode(await this.sha256(val));
  }

  private sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  private base64urlencode(a) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
}
