import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SettingsService } from 'src/app/_services/settings/settings.service';
import { Category } from 'src/app/_models/settings/category';
import { NgForm } from '@angular/forms';
import { NewSession } from 'src/app/_models/session/new.session';
import { UserService } from 'src/app/_services/user/user.service';
import { User } from 'src/app/_models/user/user';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add.user.modal.component.html',
  styleUrls: ['./add.user.modal.component.css'],
})
export class AddUserModalComponent implements OnInit {
  @Input() isVisible: boolean;
  @Output() messageCloseToEmit = new EventEmitter();
  @Output() messageAddUserToEmit = new EventEmitter();

  originalNewUser: User = {
    id: null,
    name: null,
    email: null,
    role: 'TC',
    canChange: null,
  };
  addNewUser: User = { ...this.originalNewUser };

  constructor() {}

  ngOnInit() {}

  addUser(form: NgForm): void {
    this.messageAddUserToEmit.emit(form);
  }

  handleCancel(): void {
    this.addNewUser = { ...this.originalNewUser };
    this.messageCloseToEmit.emit();
  }
}
