import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/_services/user/user.service';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-session-now',
  templateUrl: './session-now.component.html',
  styleUrls: ['./session-now.component.css'],
})
export class SessionNowComponent implements OnInit {
  now: Date = new Date();

  constructor(public signalRService: SignalRService) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngOnInit() {}
}
