import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Connection } from 'src/app/_models/control/connection';
import { Howl, Howler } from 'howler';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Directive()
@Injectable({ providedIn: 'root' })
export class AlarmService {
  playing = false;
  alarmSound: Howl = new Howl({
    src: ['/assets/alarm.wav'],
    autoplay: false,
    loop: true,
    volume: 0.5,
    onend: function () {
      console.log('Finished!');
    },
  });

  constructor(private notification: NzNotificationService) {}

  startAlarm() {
    if (!this.playing && !this.alarmSound.playing()) {
      this.playing = true;
      this.notification.config({
        nzPlacement: 'bottomLeft',
      });

      this.notification
        .warning('Alarm', 'The Alarm is currently triggered', { nzDuration: 0, nzKey: 'alarm' })
        .onClose.subscribe(() => {
          this.stopAlarm();
        });

        this.alarmSound.play();
      
    }
  }

  stopAlarm() {
    this.playing = false;
    this.alarmSound.stop();
    this.notification.remove('alarm');
  }
}
