<div class="inner-content">
  <nz-table
    #liveMonitor
    nzBordered="true"
    nzTemplateMode
    *ngIf="signalRService.dataChanged.values && signalRService.dataChanged.values.length > 0; else emptyValues"
  >
    <thead>
      <tr>
        <th style="width: 25%"></th>
        <th style="width: 25%">Track Monitor</th>
        <th style="width: 25%">Community Category Limits</th>
        <th style="width: 25%">Community Levels</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let value of signalRService.dataChanged.values">
        <td class="tblTitle">
          {{ value.displayName }}
          <div *ngIf="value.name == 'LAFMaxLow'"><small>Receptors: 1 2 3 4 7</small></div>
          <div *ngIf="value.name == 'LAFMaxHigh'"><small>Receptors: 5 6 8</small></div>
        </td>
        <td class="tblTrackM" rowspan="2" *ngIf="value.name == 'LAFMaxLow'">{{ value.track | number: '1.1-1' }} dB</td>
        <td class="tblTrackM" *ngIf="value.name != 'LAFMaxHigh' && value.name != 'LAFMaxLow'">
          {{ value.track | number: '1.1-1' }} dB
        </td>

        <td class="tblCommLimit">{{ value.limit === 0 ? '---' : (value.limit | number: '1.1-1') + ' dB' }}</td>
        <td
          class="tblCommLevel"
          [ngClass]="{
            tblCommLevelNearBreached: value.community >= value.limit - 2,
            tblCommLevelBreached: value.community >= value.limit
          }"
        >
          {{ value.community === 0 ? '---' : (value.community | number: '1.1-1') + ' dB' }}
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #emptyValues>
    <nz-empty></nz-empty>
  </ng-template>
</div>
