import { Component, OnInit } from '@angular/core';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-override',
  templateUrl: './override.component.html',
  styleUrls: ['./override.component.css'],
})
export class OverrideComponent implements OnInit {
  isVisible: boolean;
  overrideEndTime: Date;
  overrideByUser: boolean;
  overrideCategory: string;

  constructor(public signalRService: SignalRService) {}

  ngOnInit() {
    this.overrideEndTime = new Date();
    this.signalRService.overrideCat.subscribe((x) => {
      this.overrideCategory = x;
    });

    this.signalRService.overrideByUser.subscribe((x) => {
      this.overrideByUser = x;
    });

    this.signalRService.overrideEndTime.subscribe((x) => {
      this.overrideEndTime = x;
    });

    this.signalRService.showOverrideDialog.subscribe((x) => {
      this.isVisible = x;
    });
  }


}
