import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/_models/user/user';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';
import { UserService } from 'src/app/_services/user/user.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
  sessionInfo: any;
  currentUser: User;


  constructor(public signalRService: SignalRService, private userService: UserService) {}

  ngOnInit() {
    this.sessionInfo = this.signalRService.sessionInfo.getValue();

    this.userService.currentUserSubject.subscribe((x) => {
      this.currentUser = x;
    });

    this.signalRService.sessionInfo.subscribe((x) => {
      this.sessionInfo = x;
    });


  }


  incrementCategory(){
    this.signalRService.incrementCategory();
  }

}
