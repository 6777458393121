import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Connection } from 'src/app/_models/control/connection';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ControlService {
  constructor(private authService: AuthenticationService, private http: HttpClient) {}

  connect() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<Connection>(environment.apiUri + environment.getConnectUri, options);
  }
}
