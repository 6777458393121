import { Component, OnInit, Input } from '@angular/core';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-session-time',
  templateUrl: './session-time.component.html',
  styleUrls: ['./session-time.component.css'],
})
export class SessionTimeComponent implements OnInit {
  constructor(public signalRService: SignalRService) {}

  ngOnInit() {}
}
