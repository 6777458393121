<div nz-row nzType="flex" class="full-height">
  <div nz-col nzSpan="12" style="text-align: center">
    <span>Wind Direction</span>
    <h2 *ngIf="shortName; else emptyDirection" class="dir-text">{{ shortName }}</h2>
    <ng-template #emptyDirection>
      <h3>---</h3>
    </ng-template>
  </div>
  <div nz-col nzSpan="12">
    <i
      #arrowDirection
      class="compass"
      [ngClass]="direction !== undefined ? 'compass-animate' : ''"
      style="position: relative; font-size: 120px"
      nz-icon
      nzType="down-circle"
      nzTheme="outline"
    ></i>
  </div>
</div>
