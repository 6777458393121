import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-category-change-modal',
  templateUrl: './category-change-modal.component.html',
  styleUrls: ['./category-change-modal.component.css'],
})
export class CategoryChangeModalComponent implements OnInit {
  isVisible = false;
  exceedObject: { exceedValue: string; exceedOld: string; exceedNew: string };
  constructor(private signalRService: SignalRService) {}

  ngOnInit() {
    this.exceedObject = { exceedValue: '', exceedOld: '', exceedNew: '' };

    this.signalRService.showExceedDialog.subscribe((x) => {
      this.isVisible = x;
    });

    this.signalRService.exceedObject.subscribe((x) => {
      this.exceedObject = x;
    });
  }

  confirm(): void {
    this.signalRService.categoryChangeConfirm();
  }

  pause(): void {
    this.signalRService.categoryChangePause();
  }

  override(): void {
    this.signalRService.categoryChangeOverride();
  }

  timeout(): void {
    this.signalRService.categoryChangeTimeout();
  }
}
