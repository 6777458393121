import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Log } from 'src/app/_models/control/log';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-calibration-modal',
  templateUrl: './calibration-modal.component.html',
  styleUrls: ['./calibration-modal.component.css'],
})
export class CalibrationModalComponent implements OnInit {
  @Input()
  isVisible: boolean;
  calibrationStatus: 'CALIBRATING' | 'FAILED' | 'SUCCESS';
  constructor(private signalRService: SignalRService) {}

  @Output() calibrationCloseEmmiter = new EventEmitter<any>();

  ngOnInit() {
    this.calibrationStatus = 'CALIBRATING';
    this.isVisible = false;

    this.signalRService.calibrationEvent.subscribe((x) => {
      if (x) {
        this.calibrationStatus = 'SUCCESS';
      } else {
        this.calibrationStatus = 'FAILED';
      }
    });
  }

  startCalibration() {
    this.signalRService.startCalibration();
    this.calibrationStatus = 'CALIBRATING';
  }

  close() {
    this.calibrationCloseEmmiter.emit(this.calibrationStatus);
  }
}
