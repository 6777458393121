// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUri: 'https://msport.azurewebsites.net',
  apiUri: 'https://msport-test.azurewebsites.net',
  //apiUri: 'https://localhost:7071',
  responseType: 'code',
  clientId: 'msport',
  scope: 'account connect msport',
  grantType: 'authorization_code',
  refreshGrantType: 'refresh_token',
  codeChanllengeMethod: 'S256',
  authoriseUri: 'https://mycirrus.cloud/oauth/authorise?',
  authoriseResposeUri: '/oauth-result',
  getAccessTokenUri: 'https://mycirrus.cloud/oauth/access_token',
  logoutUri: 'https://mycirrus.cloud/oauth/access_token',
  getUserUri: '/user',
  getUsersUri: '/users',
  addUserUri: '/users',
  deleteUserUri: '/users/',
  editUserUri: '/users/',
  getSessionUri: '/sessions',
  getHistoryBSUri: '/history/',
  getHistoryBDUri: '/history/time',
  getReportBSUri: '/report/',
  getReportBDUri: '/report/',
  getCategoriesUri: '/categories',
  putCategoriesUri: '/categories',
  getOffsetsUri: '/offsets',
  putOffsetsUri: '/offsets',
  getConnectUri: '/connect',
  getAuthenticateUri: '/authenticate',
  getLogUri: '/log/',
  postLogUri: '/log/',
  getCalibrationsUri: '/calibrations/',
  getLastCalibrationUri: '/lastcalibration/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
