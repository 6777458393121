<div nz-row>
  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
    <div class="inner-content">
      <div nz-row nzType="flex" nzJustify="space-between" class="mb-10">
        <div nz-col>
          <h3>Categories and Community Levels</h3>
        </div>
        <div nz-col>
          <ng-container *ngIf="!editCategoryMode; else saveTpl">
            <button nz-button [disabled]="!canEdit" nzType="default" (click)="editCategory()">
              <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
            </button>
          </ng-container>
          <ng-template #saveTpl>
            <button
              nz-popconfirm
              nzTitle="Are you sure?"
              nzOkText="Yes"
              nzCancelText="No"
              nz-button
              nzType="default"
              (nzOnConfirm)="saveCategory()"
              class="mr-10"
            >
              <i nz-icon nzType="check" nzTheme="outline"></i>Save
            </button>
            <button nz-button nzType="primary" (click)="cancelEditCategory()">
              <i nz-icon nzType="close-circle" nzTheme="outline"></i>Cancel
            </button>
          </ng-template>
        </div>
      </div>
      <nz-table nzShowPagination="false" nzBordered #tblCategories [nzData]="categoriesList">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th rowspan="2">Days Per Year</th>
            <th rowspan="2">Days Left</th>
            <th colspan="4">Community Levels</th>
          </tr>
          <tr>
            <th>LAeq, 5 min</th>
            <th>LAeq, 60 min</th>
            <th>LAFMax (Low)</th>
            <th>LAFMax (High)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tblCategories.data">
            <td>{{ data.name }}</td>
            <td>
              <ng-container *ngIf="!editCategoryMode; else totalInputTpl">
                {{ data.daysTotal == -1 ? 'Unlimited' : data.daysTotal }}
              </ng-container>
              <ng-template #totalInputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="-1"
                  [nzMax]="365"
                  [(ngModel)]="editCategoryCashe[data.name].data.daysTotal"
                ></nz-input-number>
              </ng-template>
            </td>
            <td>{{ data.daysTotal == -1 ? '---' : data.daysTotal - data.daysUsed }}</td>
            <td>
              <ng-container *ngIf="!editCategoryMode; else lAeqT1InputTpl"> {{ data.lAeqT1Limit }} dB </ng-container>
              <ng-template #lAeqT1InputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="20"
                  [nzMax]="140"
                  [(ngModel)]="editCategoryCashe[data.name].data.lAeqT1Limit"
                ></nz-input-number>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="!editCategoryMode; else lAeqT2InputTpl"> {{ data.lAeqT2Limit }} dB </ng-container>
              <ng-template #lAeqT2InputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="20"
                  [nzMax]="140"
                  [(ngModel)]="editCategoryCashe[data.name].data.lAeqT2Limit"
                ></nz-input-number>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="!editCategoryMode; else lafMaxLowInputTpl">
                {{ data.lafMaxLowLimit }} dB
              </ng-container>
              <ng-template #lafMaxLowInputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="20"
                  [nzMax]="140"
                  [(ngModel)]="editCategoryCashe[data.name].data.lafMaxLowLimit"
                ></nz-input-number>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="!editCategoryMode; else lafMaxHighInputTpl">
                {{ data.lafMaxHighLimit }} dB
              </ng-container>
              <ng-template #lafMaxHighInputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="20"
                  [nzMax]="140"
                  [(ngModel)]="editCategoryCashe[data.name].data.lafMaxHighLimit"
                ></nz-input-number>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div nz-row nzType="flex" nzJustify="space-between" class="mb-10" *ngIf="!editCategoryMode">
        <div nz-col></div>
        <div nz-col style="margin-top: 10px">
          <ng-container>
            <button
              nz-button
              [disabled]="!canEdit"
              nzType="default"
              nz-popconfirm
              nzTitle="WARNING: This will reset all usage information and community levels to their defaults! Are you sure you want to proceed?"
              nzOkText="Yes"
              nzCancelText="No"
              (nzOnConfirm)="resetCategories()"
            >
              <i nz-icon nzType="undo" nzTheme="outline"></i>Reset
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="inner-content">
      <h3>Instrument Calibration</h3>
      <button
        [disabled]="signalRService.sessionInfo.getValue().running || currentUser.role == 'Viewer'"
        nz-button
        nzType="primary"
        (click)="beginCalibration()"
      >
        <i nz-icon nzType="interaction" nzTheme="outline"></i> Start Calibration
      </button>
      <p *ngIf="lastCalibration != null; else noCal">
        <span nz-text nzType="secondary"
          >Last successful calibration: {{ lastCalibration.time | date: 'dd/MM/y HH:mm:ss' }}</span
        >
      </p>
      <ng-template #noCal>
        <p>
          <span nz-text nzType="danger">No Calibration done today.</span>
        </p>
      </ng-template>
    </div>
  </div>
  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="12">
    <div class="inner-content">
      <div nz-row nzType="flex" nzJustify="space-between" class="mb-10">
        <div nz-col>
          <h3>Reduction Indices</h3>
        </div>
        <div nz-col>
          <ng-container *ngIf="!editIndiciesMode; else saveIndiciesTpl">
            <button [disabled]="!canEdit" nz-button nzType="default" (click)="editReduction()">
              <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
            </button>
          </ng-container>
          <ng-template #saveIndiciesTpl>
            <button
              nz-popconfirm
              nzTitle="Are you sure?"
              nzOkText="Yes"
              nzCancelText="No"
              nz-button
              nzType="default"
              (nzOnConfirm)="saveReduction()"
              class="mr-10"
            >
              <i nz-icon nzType="check" nzTheme="outline"></i>Save
            </button>
            <button nz-button nzType="primary" (click)="cancelEditReduction()">
              <i nz-icon nzType="close-circle" nzTheme="outline"></i>Cancel
            </button>
          </ng-template>
        </div>
      </div>
      <nz-table
        nzShowPagination="false"
        nzFrontPagination="false"
        nzBordered
        #tblIndicies
        [nzData]="reductionIndiciesOrigin"
      >
        <thead>
          <tr>
            <th>Type</th>
            <th>Group</th>
            <th>Offset</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tblIndicies.data">
            <td style="width: 30%">{{ data.type }}</td>
            <td style="width: 30%">{{ data.name }}</td>
            <td>
              <ng-container *ngIf="!editIndiciesMode; else offsetInputTpl"> {{ data.value }} dB </ng-container>
              <ng-template #offsetInputTpl>
                <nz-input-number
                  validateOnBlur
                  required
                  [nzMin]="-60"
                  [nzMax]="0"
                  nz-input
                  [(ngModel)]="editIndiciesCashe[data.name].data.value"
                ></nz-input-number>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div nz-row nzType="flex" nzJustify="space-between" class="mb-10" *ngIf="!editIndiciesMode">
        <div nz-col></div>
        <div nz-col style="margin-top: 10px">
          <ng-container>
            <button
              nz-button
              [disabled]="!canEdit"
              nzType="default"
              nz-popconfirm
              nzTitle="WARNING: This will reset all reduction indices to their defaults! Are you sure you want to proceed?"
              nzOkText="Yes"
              nzCancelText="No"
              (nzOnConfirm)="resetIndices()"
            >
              <i nz-icon nzType="undo" nzTheme="outline"></i>Reset
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<app-calibration-modal [isVisible]="calibrationVisible" (calibrationCloseEmmiter)="closeCalibration($event)">
</app-calibration-modal>
