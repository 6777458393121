import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../_guards/auth.guard';
import { TrackControlComponent } from './track-control/track-control.component';
import { TrackSettingsComponent } from './track-settings/track-settings.component';
import { HistoryComponent } from './history/history.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './users/users.component';
import { CanDeactivateGuard } from '../_guards/can-deactivate-guard.service';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard/track-control',
      },
      {
        path: 'track-control',
        component: TrackControlComponent,
        data: {
          breadcrumb: 'Track Control',
        },
      },
      {
        path: 'track-settings',
        component: TrackSettingsComponent,
        data: {
          breadcrumb: 'Track Settings',
        },
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          breadcrumb: 'History',
        },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          breadcrumb: 'Reports',
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        data: {
          breadcrumb: 'Users',
        },
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
