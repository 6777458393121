import { Component, OnInit } from '@angular/core';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-wind',
  templateUrl: './wind.speed.component.html',
  styleUrls: ['./wind.speed.component.css'],
})
export class WindSpeedComponent implements OnInit {
  format = () => this.signalRService.weatherInfo.windSpeed + ' m/s';

  constructor(public signalRService: SignalRService) {}

  ngOnInit() {}
}
