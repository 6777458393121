import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';
import { UserService } from 'src/app/_services/user/user.service';
import { User } from 'src/app/_models/user/user';

@Component({
  selector: 'app-session-control',
  templateUrl: './session-control.component.html',
  styleUrls: ['./session-control.component.css'],
})
export class SessionControlComponent implements OnInit {
  @Output() messageRestartToEmit = new EventEmitter();
  @Output() messageStartToEmit = new EventEmitter();
  @Output() messagePauseToEmit = new EventEmitter();
  @Output() messageStopToEmit = new EventEmitter();

  @Output() messageCancelOverrideToEmit = new EventEmitter();

  isOverride: boolean;

  canStop: any;
  canStart: boolean;
  sessionInfo: any;
  currentUser: User;

  constructor(public signaRService: SignalRService, private userService: UserService) {}

  ngOnInit() {
    this.canStart = this.userService.getCurrentUserValue.role === 'TC';

    this.userService.currentUserSubject.subscribe((x) => {
      this.canStart = x.role === 'TC';
    });

    this.sessionInfo = this.signaRService.sessionInfo.getValue();

    this.userService.currentUserSubject.subscribe((x) => {
      this.currentUser = x;
    });

    this.signaRService.sessionInfo.subscribe((x) => {
      this.sessionInfo = x;
    });

    this.signaRService.showOverrideDialog.subscribe((x) => {
      this.isOverride = x;
    });
  }

  sendStartToParent() {
    this.messageStartToEmit.emit();
  }

  sendRestartToParent() {
    this.messageRestartToEmit.emit();
  }


  sendPauseToParent() {
    this.messagePauseToEmit.emit();
  }

  sendStopToParent() {
    this.messageStopToEmit.emit();
  }

  sendCancelOverrideToParent() {
    this.messageCancelOverrideToEmit.emit();
  }
}
