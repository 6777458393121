import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models/user/user';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getUsers(): Observable<User[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<User[]>(environment.apiUri + environment.getUsersUri, options);
  }

  addUser(user: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.post<User>(environment.apiUri + environment.addUserUri, user, options);
  }

  updateUser(userId: string, userRole: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
      params: {
        role: userRole,
      },
    };

    return this.http.put(environment.apiUri + environment.editUserUri + userId, null, options);
  }

  deleteUser(userId: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.delete(environment.apiUri + environment.deleteUserUri + userId, options);
  }
}
