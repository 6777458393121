<div class="inner-content">
  <p>Time Elapsed:</p>
  <h3 *ngIf="signalRService.dataChanged.elapsedTime; else emptyTime">
    {{ '2000-01-01T' + signalRService.dataChanged.elapsedTime + 'Z' | date: 'HH:mm:ss' }}
  </h3>
  <p>Predicted Time Remaining:</p>
  <h3 [ngSwitch]="signalRService.dataChanged.remainingTime">
    <span *ngSwitchCase="null">---</span>
    <span *ngSwitchCase="'1.00:00:00'">---</span>
    <span *ngSwitchDefault>{{
      '2000-01-01T' + signalRService.dataChanged.remainingTime + 'Z' | date: 'HH:mm:ss'
    }}</span>
  </h3>
  <ng-template #emptyTime>
    <h3>---</h3>
  </ng-template>
</div>
