<nz-modal [nzFooter]="null" [ngSwitch]="calibrationStatus" [(nzVisible)]="isVisible" nzTitle="" (nzOnCancel)="close()">
  <ng-container *ngSwitchCase="'CALIBRATING'">
    <div class="ant-result-icon">
      <div style="text-align: center; font-size: 100px">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
      </div>
    </div>
    <div class="ant-result-title ng-star-inserted">Calibrating</div>
    <div class="ant-result-subtitle ng-star-inserted">
      Place the calibrator on the intrument and press start on the calibrator.
    </div>
    <div class="ant-result-extra">
      <button nz-button nzType="primary" (click)="close()">Cancel</button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'FAILED'">
    <nz-result nzStatus="error" nzTitle="Calibration Failed!">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="startCalibration()">Retry</button>
      </div>
    </nz-result>
  </ng-container>
  <ng-container *ngSwitchCase="'SUCCESS'">
    <nz-result
      nzStatus="success"
      nzTitle="Calibration Successful!"
      nzSubTitle="Remove the calibrator and replace the windshield."
    >
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="close()">Close</button>
      </div>
    </nz-result>
  </ng-container>
</nz-modal>
