import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Log } from 'src/app/_models/control/log';
import { UserService } from 'src/app/_services/user/user.service';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/_models/user/user';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit, OnChanges {
  @ViewChild('scrollMe', { static: true }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('focusMe', { static: true }) input: ElementRef;

  @Input() notes: Log[];
  @Output() addNoteEmmiter = new EventEmitter<any>();
  sessionInfo: any;
  currentUser: User;

  constructor(public signalRService: SignalRService, private userService: UserService) {}

  ngOnInit() {
    this.notes = [];

    this.userService.currentUserSubject.subscribe((x) => {
      this.currentUser = x;
    });

    this.signalRService.sessionInfo.subscribe((x) => {
      this.sessionInfo = x;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.scrollToBottom();
    this.input.nativeElement.focus();
  }

  scrollToBottom(): void {
    setTimeout(() => this.viewPort.scrollTo({ bottom: -1000, behavior: 'smooth' }), 50);
  }

  addNote(form: NgForm) {
    if (form.value.message !== '') {
      this.addNoteEmmiter.emit(form.value);
      form.resetForm();
    }
  }
}
