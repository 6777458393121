<nz-layout>
  <nz-content>
    <div class="inner-content">
      <div class="logo-img2">
        <img src="../../assets/img/cirrus-logo.png" class="login-logo" />
      </div>
      <div class="logo-img">
        <img src="../../assets/img/QTLogoTall.png" class="login-logo" />
      </div>
      <div class="container-login-btn">
        <button nz-button nzType="default" nzSize="large" (click)="goToLogin()" nzGhost class="btn-login">
          <i nz-icon>
            <svg width="1.3em" height="1.3em" viewBox="0 0 24 15.105">
              <g transform="translate(-8.8764 -370.89)">
                <g transform="matrix(.039133 0 0 .039133 8.529 370.89)">
                  <path
                    d="m500.03 122.06c-28.275-71.479-97.947-122.06-179.48-122.06-97.598 0-178.22 72.46-191.14 166.51-3.466-0.329-6.976-0.515-10.53-0.515-60.75 0-110 49.248-110 110s49.248 110 110 110h371.13c72.996 0 132.17-59.175 132.17-132.17 0-69.623-53.844-126.63-122.15-131.76z"
                    fill="#be1e2d"
                    stroke-linejoin="round"
                    stroke-width=".2"
                  />
                </g>
              </g>
              <g transform="translate(-8.8764 -370.89)" fill="#fff" stroke-width=".038583">
                <path
                  d="m15.328 378.08-0.36597-0.36957 0.27008-0.25418c1.7733-1.6689 4.3541-2.4637 6.8485-2.1092 1.6303 0.23171 3.2533 1.0035 4.4686 2.1248l0.24374 0.22492-0.38595 0.38262-0.38595 0.38264-0.24362-0.22578c-0.92889-0.86086-2.1684-1.4865-3.4734-1.7531-2.1961-0.44871-4.504 0.17141-6.2397 1.6766-0.1713 0.14854-0.3247 0.27451-0.34091 0.27992-0.01621 5e-3 -0.19415-0.15647-0.39543-0.35974z"
                />
                <path
                  d="m17.007 379.76-0.3798-0.38123 0.20467-0.18552c0.89409-0.81044 2.0411-1.3498 3.2191-1.5136 0.48458-0.0674 1.3451-0.0519 1.8435 0.0334 1.0091 0.17252 1.9924 0.62213 2.7906 1.276 0.14854 0.12169 0.30389 0.25894 0.34521 0.30503l0.07512 0.0838-0.37247 0.37248-0.37247 0.37247-0.18494-0.1618c-0.24297-0.21256-0.81793-0.6029-1.0915-0.74098-0.34182-0.17255-0.95101-0.37105-1.3481-0.43925-0.46378-0.0797-1.2752-0.0809-1.7239-3e-3 -0.86434 0.15073-1.7723 0.59417-2.4294 1.1865l-0.19598 0.17666z"
                />
                <path
                  d="m18.75 381.51-0.37704-0.37846 0.18485-0.15444c0.43382-0.36245 0.88101-0.59961 1.4236-0.7549 0.43746-0.12524 1.3225-0.12755 1.7708-5e-3 0.54219 0.14864 1.0717 0.42983 1.4458 0.76781l0.1601 0.14464-0.74796 0.75079-0.23604-0.17392c-0.31893-0.23497-0.55442-0.34942-0.90183-0.43827-0.7333-0.18755-1.5434-0.0154-2.1139 0.44926-0.1152 0.0938-0.21436 0.17059-0.22036 0.17059-6e-3 0-0.18058-0.1703-0.38796-0.37846z"
                />
                <path
                  d="m20.478 384.52c-0.1974-0.0922-0.45359-0.38106-0.51259-0.57798-0.1018-0.33978-0.03277-0.65631 0.20107-0.92197 0.2011-0.22847 0.40035-0.31821 0.70891-0.31929 0.30282-9.9e-4 0.53927 0.10361 0.72549 0.32117 0.37903 0.44282 0.28525 1.0848-0.20924 1.4323-0.1444 0.10149-0.20455 0.1181-0.46192 0.12761-0.22631 8e-3 -0.33255-6e-3 -0.45173-0.0618z"
                />
              </g>
            </svg>
          </i>
          Login with MyCirrus
        </button>
      </div>
    </div>
  </nz-content>
</nz-layout>
