<div class="inner-content">
  <p>Category of Use</p>   <button               nz-popconfirm
  nzTitle="Are you sure you would like to increment the category?"
  nzOkText="Yes"
  nzCancelText="No"
  style="float:right; height: 50px; width: 50px;"
  nzTooltipTitle="Incremement Category" nzTooltipPlacement="bottom"
  nz-button (nzOnConfirm)="incrementCategory()" [disabled]="!sessionInfo.running || currentUser.name !== sessionInfo.controller || signalRService.sessionInfo.getValue().category == 'A'">
    <i nz-icon nzType="arrow-up" nzTheme="outline"></i>
  </button>
  <h1 class="cat-color-red" *ngIf="signalRService.sessionInfo.getValue().category; else emptyCategory">
    {{ signalRService.sessionInfo.getValue().category }}
  </h1>

  <ng-template #emptyCategory>
    <h1 class="cat-color-red">---</h1>
  </ng-template>
</div>
