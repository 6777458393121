import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { HistoryBySession } from 'src/app/_models/history/history.by.session';
import { HistoryByDate } from 'src/app/_models/history/history.by.date';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class HistoryService {
  constructor(private http: HttpClient, private authService: AuthenticationService, public datepipe: DatePipe) {}

  getHistoryBySession(sessionId: string) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<HistoryBySession>(environment.apiUri + environment.getHistoryBSUri + sessionId, options);
  }

  getHistoryByDate(fromDate: Date, toDate: Date) {
    const getParams = new HttpParams();

    getParams.set(
      'from',
      fromDate.getFullYear().toString() + (fromDate.getMonth() + 1).toString() + ('0' + fromDate.getDate()).slice(-2)
    );
    getParams.set(
      'to',
      toDate.getFullYear().toString() + (toDate.getMonth() + 1).toString() + ('0' + toDate.getDate()).slice(-2)
    );
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<HistoryByDate>(
      environment.apiUri +
        environment.getHistoryBDUri +
        '?from=' +
        this.datepipe.transform(fromDate, 'yyyyMMdd') +
        '&to=' +
        this.datepipe.transform(toDate, 'yyyyMMdd'),
      options
    );
  }
}
