import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import Helper from 'src/app/_helpers/helper';
import { LogService } from 'src/app/_services/logs/log.service';
import { Log } from 'src/app/_models/control/log';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';
import { SessionService } from 'src/app/_services/session/session.service';
import { HubConnectionState } from '@microsoft/signalr';
import { addMinutes, addSeconds } from 'date-fns';
import { AlarmService } from 'src/app/_services/alarm/alarm.service';
import { CanDeactivateGuard } from 'src/app/_guards/can-deactivate-guard.service';

@Component({
  selector: 'app-track-control',
  templateUrl: './track-control.component.html',
  styleUrls: ['./track-control.component.css'],
})
export class TrackControlComponent implements OnInit, CanDeactivateGuard {
  startNewSession = false;
  logs: Log[];
  weatherOverride = false;
  isError = false;
  errorMessage = '';
  systemStatus = { weather: false };
  updateLogTimer;

  constructor(
    private message: NzMessageService,
    private logService: LogService,
    public signalRService: SignalRService,
    public sessionService: SessionService,
    public alarmService: AlarmService
  ) {}

  ngOnDestroy() {
    clearInterval(this.updateLogTimer);
  }

  ngOnInit() {
    this.signalRService.getSessionInfo().subscribe(() => {
      this.updateLogs();
    });

    this.updateLogTimer = setInterval(() => {
      this.updateLogs();
    }, 15000);

    this.signalRService.connectionState.subscribe((x: HubConnectionState) => {
      switch (x) {
        case HubConnectionState.Connected:
          this.isError = false;
          break;
        case HubConnectionState.Disconnected || HubConnectionState.Disconnecting:
          this.isError = true;
          this.errorMessage = 'Not connected to Quantum';
          break;
        case HubConnectionState.Reconnecting:
          this.isError = true;
          this.errorMessage = 'Reconnecting to Quantum';
          break;
        case HubConnectionState.Connecting:
          this.isError = true;
          this.errorMessage = 'Connecting to Quantum';
          break;
        default:
          break;
      }
    });

    this.signalRService.systemStatus.subscribe((status) => {
      this.systemStatus = status;
    });

    this.signalRService.limitExceededEvent.subscribe((x) => {
      this.updateLogs();
      // this.showLimitExceeded(x);
    });

    this.signalRService.sessionStoppedEvent.subscribe((x) => {
      this.alarmService.stopAlarm();
      this.signalRService.updateSessionInfo();
      this.logs = [];
    });
  }

  exceedTestButton() {
    // this.showLimitExceeded({ value: 60, changeCategory:false, oldCategory:"", newCategory:"" });
  }

  // Start new session modal
  getStartMessage() {
    // Show modal
    if (!this.signalRService.sessionInfo.getValue().running) {
      this.startNewSession = true;
    }
  }

  // Get Info from modal and start new session
  getStartMessageValue(message: any) {
    this.signalRService.updateSessionInfo().then(() => {
      // check if other session is running
      if (this.signalRService.sessionInfo.getValue().running || this.signalRService.sessionInfo.getValue().paused) {
        Helper.CreateSuccessMessage(this.message, 'Other session is running.');
      } else {
        // start new session
        this.signalRService
          .startSession(message.category, message.weather, message.vehicles, message.drivers, message.purpose)
          .then(() => {
            this.updateLogs();
            this.sessionService.resetSessionList();
          });
        // close new session modal
        this.closeNewSession();
      }
    });
  }

  // close new session modal
  closeNewSession() {
    this.startNewSession = false;
  }



  
  getRestartMessage() {
    // call pause/resume session
    this.signalRService.restartSession().then(() => {
      this.signalRService.showOverrideDialog.next(false);
      this.signalRService.updateSessionInfo();
      // this.updateLogs();
    });
  }

  // Pause/Resume current session
  getPauseMessage() {
    // call pause/resume session
    this.signalRService.pauseResumeSession().then(() => {
      this.signalRService.showOverrideDialog.next(false);
      this.signalRService.updateSessionInfo();
      // this.updateLogs();
    });
  }

  // Stop current session
  getStopMessage() {
    // call stop session
    this.signalRService.stopSession().then((result) => {
      this.signalRService.updateSessionInfo();
      this.logs = [];
    });
  }

  // get new note modal info
  getNewNote(message: any) {
    // add new note
    this.logService.addLogs(this.signalRService.sessionInfo.getValue().sessionId, message).subscribe(
      () => {
        this.updateLogs();
      },
      () => {
        Helper.CreateErrorMessage(this.message, 'Error');
      }
    );
  }

  // get list of notes
  private updateLogs() {
    if (this.signalRService.sessionInfo.getValue()) {
      var sessionId = this.signalRService.sessionInfo.getValue().sessionId;

      if (sessionId) {
        this.logService.getLogs(sessionId).subscribe((data) => {
          this.logs = data;
        });
      }
    }
  }

  canDeactivate() {
    if (this.signalRService.showOverrideDialog.getValue()) {
      this.message.create('error', `Session current overridden`);
      return false;
    }
  }
}
