<div class="inner-content">
  <nz-page-header nzTitle="Administrators & Track Controllers">
    <nz-page-header-extra>
      <button nz-button nzType="default" (click)="openAddModal()">Add User</button>
    </nz-page-header-extra>
  </nz-page-header>
  <nz-table nzBordered #usersTable [nzData]="usersList" [nzLoading]="loading">
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Access Level</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of usersTable.data">
        <td>{{ data.name }}</td>
        <td>{{ data.email }}</td>
        <td>
          <div class="editable-cell" *ngIf="data.canChange; else editTpl">
            <nz-select
              class="full-width"
              nzShowSearch
              name="role"
              id="role"
              (ngModelChange)="modelChanged(data.id, $event)"
              [(ngModel)]="data.role"
              nzPlaceHolder="Select a role"
            >
              <nz-option nzLabel="Viewer" nzValue="Viewer"></nz-option>
              <nz-option nzLabel="Track Controller" nzValue="TC"></nz-option>
              <nz-option nzLabel="Administrator" nzValue="Admin"></nz-option>
            </nz-select>
          </div>
          <ng-template #editTpl>
            <nz-select
              class="full-width"
              nzDisabled="true"
              nzShowSearch
              name="role"
              id="role"
              [(ngModel)]="data.role"
              nzPlaceHolder="Select a role"
            >
              <nz-option nzLabel="Viewer" nzValue="Viewer"></nz-option>
              <nz-option nzLabel="Track Controller" nzValue="TC"></nz-option>
              <nz-option nzLabel="Administrator" nzValue="Admin"></nz-option>
            </nz-select>
          </ng-template>
        </td>
        <td>
          <a
            *ngIf="data.canChange"
            nz-button
            nzType="primary"
            nz-popconfirm
            nzPopconfirmTitle="Are you sure?"
            nzPopconfirmPlacement="bottom"
            nzOkText="Yes"
            nzCancelText="No"
            (nzOnConfirm)="deleteUser(data.id)"
            >Delete</a
          >
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<app-add-user-modal
  [isVisible]="addVisible"
  (messageCloseToEmit)="closeAddModal()"
  (messageAddUserToEmit)="submitAddModal($event)"
></app-add-user-modal>
