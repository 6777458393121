import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { Log } from 'src/app/_models/control/log';

@Injectable({ providedIn: 'root' })
export class LogService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getLogs(sessionId: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    return this.http.get<Log[]>(environment.apiUri + environment.getLogUri + sessionId, { headers });
  }

  addLogs(sessionId: string, newMessage) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    const newLog = {
      time: new Date(),
      message: newMessage.message,
    };

    return this.http.post(environment.apiUri + environment.postLogUri + sessionId, newLog, options);
  }
}
