import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { CalibrationLog } from 'src/app/_models/calibration/calibration.log';
import { format } from 'date-fns';
import { SignalRService } from '../signalr/signalr.service';

@Injectable({ providedIn: 'root' })
export class CalibrationService implements OnInit {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private signalRService: SignalRService
  ) {}
  ngOnInit() {
    this.signalRService.calibrationEvent.subscribe((success) => {
      if (success) {
        this.putLastCalibrationTime(new Date());
      }
    });
  }

  getCalibrations(from: Date, to: Date) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    const params = {
      from: format(from, 'yyyyMMdd'),
      to: format(to, 'yyyyMMdd'),
    };

    return this.http.get<CalibrationLog[]>(environment.apiUri + environment.getCalibrationsUri, { headers, params });
  }

  getCalibration(time: Date) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    const params = {
      time: format(time, 'yyyyMMddHHmm'),
    };

    return this.http.get<CalibrationLog>(environment.apiUri + environment.getCalibrationsUri, { headers, params });
  }

  getLastCalibrationTime() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    return this.http.get<Date>(environment.apiUri + environment.getLastCalibrationUri, { headers });
  }

  putLastCalibrationTime(date: Date) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });
    return this.http.post(environment.apiUri + environment.getLastCalibrationUri, date.toUTCString(), { headers });
  }
}
