import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { NgZorroAntdModule, NZ_I18N, en_US, NzTableModule, NzListModule, NzSkeletonModule } from 'ng-zorro-antd';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardRoutingModule } from './dasboard-routing.module';
import { TrackControlComponent } from './track-control/track-control.component';
import { TrackSettingsComponent } from './track-settings/track-settings.component';
import { HistoryComponent } from './history/history.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './users/users.component';
import { SessionNowComponent } from './track-control/session-now/session-now.component';
import { FormsModule } from '@angular/forms';
import { CategoryComponent } from './track-control/category/category.component';
import { SessionTimeComponent } from './track-control/session-time/session-time.component';
import { SessionControlComponent } from './track-control/session-control/session-control.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { SessionStartModalComponent } from './track-control/session-control/modal/session-start-modal.component';
import { NotesComponent } from './track-control/notes/notes.component';
import { ValuesComponent } from './track-control/values/values.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { WindSpeedComponent } from './track-control/wind-speed/wind.speed.component';
import { WindDirectionComponent } from './track-control/wind-direction/wind.direction.component';
import { AddUserModalComponent } from './users/modals/add-user/add.user.modal.component';
import { CategoryChangeModalComponent } from './track-control/modal/category-change-modal.component';
import { CalibrationModalComponent } from './track-settings/calibration/modal/calibration-modal.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { IconModule } from '@ant-design/icons-angular';
import { OverrideComponent } from './track-control/override/override.component';

@NgModule({
  declarations: [
    DashboardComponent,
    TrackControlComponent,
    TrackSettingsComponent,
    HistoryComponent,
    ReportsComponent,
    UsersComponent,
    SessionNowComponent,
    CategoryComponent,
    SessionTimeComponent,
    SessionControlComponent,
    SessionStartModalComponent,
    CategoryChangeModalComponent,
    NotesComponent,
    ValuesComponent,
    WindSpeedComponent,
    WindDirectionComponent,
    AddUserModalComponent,
    CalibrationModalComponent,
    OverrideComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    BrowserModule,
    DashboardRoutingModule,
    FormsModule,
    ScrollingModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzListModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    IconModule,
  ],
  entryComponents: [],
})
export class DashboardModule {}
