import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { Category } from 'src/app/_models/settings/category';
import { Observable } from 'rxjs';
import { ReductionIndicies } from 'src/app/_models/settings/reduction.indicies';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getCategoryList(): Observable<Category[]> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<Category[]>(environment.apiUri + environment.getCategoriesUri, options);
  }

  changeCategoryList(newCategory: Category[]) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(environment.apiUri + environment.putCategoriesUri, newCategory, options);
  }

  resetCategoryList() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
        'Content-Type': 'application/json',
      }),
    };

    return this.http.delete(environment.apiUri + environment.putCategoriesUri, options);
  }

  getOffsetsList() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };
    return this.http.get<ReductionIndicies[]>(environment.apiUri + environment.getOffsetsUri, options);
  }

  changeOffsetsList(newIndicies: ReductionIndicies[]) {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(environment.apiUri + environment.putOffsetsUri, newIndicies, options);
  }

  resetOffsetsList() {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
        'Content-Type': 'application/json',
      }),
    };

    return this.http.delete(environment.apiUri + environment.putOffsetsUri, options);
  }
}
