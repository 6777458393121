<div class="inner-content">
  <h1 class="normal" *ngIf="!sessionInfo.running && !sessionInfo.paused && !isOverride">Ready</h1>
  <h1 class="started" *ngIf="sessionInfo.running && !sessionInfo.paused && !isOverride">Session Running</h1>
  <h1 class="normal" *ngIf="sessionInfo.paused && !isOverride">Session Paused</h1>
  <h1 class="normal" *ngIf="isOverride">Session Overridden</h1>

  <div nz-row nzGutter="8" class="bottom">
    <div col-8 nz-col nzSpan="8" *ngIf="isOverride">
      <button nzBlock nz-button nzType="primary" (click)="sendCancelOverrideToParent()">Cancel Override</button>
    </div>
    <div col-8 nz-col nzSpan="8" *ngIf="!sessionInfo.running">
      <button
      nzBlock
      nz-button
      nzType="primary"
      (click)="sendStartToParent()"
      [disabled]="sessionInfo.running || !canStart"
    >
      <i nz-icon nzType="caret-right" nzTheme="outline"></i>
      Start
    </button>
  </div>
  <div col-8 nz-col nzSpan="8" *ngIf="sessionInfo.running">
    <button
          nzBlock
          nz-button
          nzType="primary"
          (click)="sendRestartToParent()"
          [disabled]="!sessionInfo.paused || currentUser.name !== sessionInfo.controller"
        >
          <i nz-icon nzType="redo" nzTheme="outline"></i>
          Restart
        </button>
    </div>

    <div col-8 nz-col nzSpan="8">
      <button
        nzBlock
        nz-button
        nzType="primary"
        (click)="sendPauseToParent()"
        
        *ngIf="!sessionInfo.paused; else pausedSession"
        [disabled]="!sessionInfo.running || currentUser.name !== sessionInfo.controller"
      >
        <i nz-icon nzType="pause" nzTheme="outline"></i>
        Pause
      </button>
      <ng-template #pausedSession>
        <button
          nzBlock
          nz-button
          nzType="primary"
          (click)="sendPauseToParent()"
          [disabled]="!sessionInfo.running || currentUser.name !== sessionInfo.controller"
        >
        <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Resume
        </button>
      </ng-template>
    </div>
    <div col-8 nz-col nzSpan="8">
      <button
        nzBlock
        nz-button
        nzType="primary"
        nz-popconfirm
        nzTitle="Are you sure?"
        nzOkText="Yes"
        nzCancelText="No"
        (nzOnConfirm)="sendStopToParent()"
        [disabled]="!sessionInfo.running || currentUser.name !== sessionInfo.controller"
      >
        <i nz-icon>
          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
            <path
              d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z"
            ></path>
          </svg>
        </i>
        End
      </button>
    </div>
  </div>
</div>
