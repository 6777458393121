import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/_services/settings/settings.service';
import { Category } from 'src/app/_models/settings/category';
import { NzMessageService } from 'ng-zorro-antd';
import { ReductionIndicies } from 'src/app/_models/settings/reduction.indicies';
import Helper from 'src/app/_helpers/helper';
import { UserService } from 'src/app/_services/user/user.service';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';
import { CalibrationLog } from 'src/app/_models/calibration/calibration.log';
import { CalibrationService } from 'src/app/_services/calibrations/calibrations.service';
import { User } from 'src/app/_models/user/user';
import { isToday } from 'date-fns';

const CalibrationLocalStorageKey = 'LastCalibration';

@Component({
  selector: 'app-track-settings',
  templateUrl: './track-settings.component.html',
  styleUrls: ['./track-settings.component.css'],
})
export class TrackSettingsComponent implements OnInit {
  categoriesList: Category[];
  reductionIndiciesOrigin: ReductionIndicies[];
  editCategoryCashe: { [key: string]: { data: Category } } = {};
  editIndiciesCashe: { [key: string]: { data: ReductionIndicies } } = {};
  editCategoryMode = false;
  editIndiciesMode = false;
  canEdit = false;
  currentUser: User;
  calibrationVisible = false;

  lastCalibration: CalibrationLog;

  constructor(
    public settingsService: SettingsService,
    public message: NzMessageService,
    public userService: UserService,
    public signalRService: SignalRService,
    public calibrationService: CalibrationService
  ) {}

  ngOnInit() {
    this.calibrationService.getCalibration(new Date()).subscribe((x) => (this.lastCalibration = x));

    if (this.lastCalibration == null || this.lastCalibration.time == null) {
      this.calibrationService.getLastCalibrationTime().subscribe((x) => {
        var calTime = new Date(x);
        if (isToday(calTime)) this.lastCalibration = { time: calTime };
      });
    }

    this.userService.currentUserSubject.subscribe((x) => {
      this.canEdit = x.role === 'Admin';
      this.currentUser = x;
    });

    this.getCategories();
    this.getOffsets();
  }

  getCategories() {
    this.settingsService.getCategoryList().subscribe((data) => {
      this.categoriesList = data;
      this.updateEditCategoryMode();
    });
  }

  getOffsets() {
    this.settingsService.getOffsetsList().subscribe((data) => {
      this.updateReductionMode(data);
      this.reductionIndiciesOrigin = data;
    });
  }

  editCategory() {
    if (this.canEdit) {
      if (!this.editCategoryMode) {
        this.editCategoryMode = true;
      } else {
        this.editCategoryMode = false;
      }
    }
  }

  saveCategory() {
    if (this.validateCategory(this.editCategoryCashe)) {
      for (const i of this.categoriesList) {
        Object.assign(i, this.editCategoryCashe[i.name].data);
      }
      this.settingsService.changeCategoryList(this.categoriesList).subscribe(
        () => {
          Helper.CreateSuccessMessage(this.message, 'Category updated');
        },
        (error) => {
          // Helper.CreateErrorMessage(
          //   this.message,
          //   'Server error: ' + error.error.Message
          // );
        }
      );
      this.editCategoryMode = false;
    }
  }

  cancelEditCategory(): void {
    for (const i of this.categoriesList) {
      this.editCategoryCashe[i.name] = {
        data: { ...i },
      };
    }
    this.editCategoryMode = false;
  }

  updateEditCategoryMode(): void {
    this.categoriesList.forEach((item) => {
      this.editCategoryCashe[item.name] = {
        data: { ...item },
      };
    });
  }

  editReduction() {
    if (this.canEdit) {
      if (!this.editIndiciesMode) {
        this.editIndiciesMode = true;
      } else {
        this.editIndiciesMode = false;
      }
    }
  }

  saveReduction() {
    if (this.validateIndicies(this.editIndiciesCashe)) {
      for (const i of this.reductionIndiciesOrigin) {
        Object.assign(i, this.editIndiciesCashe[i.name].data);
      }

      this.settingsService.changeOffsetsList(this.reductionIndiciesOrigin).subscribe(
        () => {
          Helper.CreateSuccessMessage(this.message, 'Indicies updated');
        },
        (error) => {
          // Helper.CreateErrorMessage(
          //   this.message,
          //   'Server error: ' + error.error.Message
          // );
        }
      );
      this.editIndiciesMode = false;
    }
  }

  cancelEditReduction() {
    for (const i of this.reductionIndiciesOrigin) {
      this.editIndiciesCashe[i.name] = {
        data: { ...i },
      };
    }
    this.editIndiciesMode = false;
  }

  updateReductionMode(data: ReductionIndicies[]) {
    data.forEach((item) => {
      this.editIndiciesCashe[item.name] = {
        data: { ...item },
      };
    });
  }

  beginCalibration() {
    if (this.signalRService.sessionInfo.getValue().running) {
      Helper.CreateErrorMessage(this.message, 'Cannot calibrate whilst a session is in progresss.');
    } else {
      this.calibrationVisible = true;
      this.signalRService.startCalibration();
    }
  }

  closeCalibration(status: string) {
    // if (status === 'SUCCESS') {
    //   this.lastCalibration = { time: new Date() };
    // }
    this.calibrationVisible = false;
  }

  validateCategory(values: { [key: string]: { data: Category } }) {
    let invalidMessage = '';
    let isValid = true;
    let dayMessage = false;
    let levelMessage = false;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const newDaysTotal = values[key].data.daysTotal;
        const leq1 = values[key].data.lAeqT1Limit;
        const leq2 = values[key].data.lAeqT2Limit;
        const lafMaxLow = values[key].data.lafMaxLowLimit;
        const lafMaxHigh = values[key].data.lafMaxHighLimit;

        if (newDaysTotal < -1 || newDaysTotal > 365) {
          isValid = false;
          if (!dayMessage) {
            dayMessage = true;
            invalidMessage += 'Days Per Year must be between -1 and 365.<br/>';
          }
        }

        if (
          leq1 < 20 ||
          leq1 > 140 ||
          leq2 < 20 ||
          leq2 > 140 ||
          lafMaxLow < 20 ||
          lafMaxLow > 140 ||
          lafMaxHigh < 20 ||
          lafMaxHigh > 140
        ) {
          isValid = false;
          if (!levelMessage) {
            levelMessage = true;
            invalidMessage += 'Levels must be between 20 and 140.<br/>';
          }
        }
      }
    }
    if (!isValid) {
      Helper.CreateErrorMessage(this.message, invalidMessage);
    }
    return isValid;
  }

  validateIndicies(values: { [key: string]: { data: ReductionIndicies } }) {
    let isValid = true;
    let invalidMessage = '';
    let valMessage = false;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const val = values[key].data.value;
        if (val < -60 || val > 0) {
          isValid = false;
          if (!valMessage) {
            valMessage = true;
            invalidMessage += 'Reduction Indicies must be between -60 and 0';
          }
        }
      }
    }

    if (!isValid) {
      Helper.CreateErrorMessage(this.message, invalidMessage);
    }
    return isValid;
  }

  resetCategories() {
    this.settingsService.resetCategoryList().subscribe(
      () => {
        Helper.CreateSuccessMessage(this.message, 'Categories reset');
        this.getCategories();
      },
      (error) => {
        // Helper.CreateErrorMessage(
        //   this.message,
        //   'Server error: ' + error.error.Message
        // );
      }
    );
  }

  resetIndices() {
    this.settingsService.resetOffsetsList().subscribe(
      () => {
        Helper.CreateSuccessMessage(this.message, 'Indices reset');
        this.getOffsets();
      },
      (error) => {
        Helper.CreateErrorMessage(this.message, 'Server error: ' + error.error.Message);
      }
    );
  }
}
