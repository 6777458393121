import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/auth/authentication.service';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user/user.service';

@Component({
  selector: 'app-oauth-result',
  templateUrl: './oauth-result.component.html',
  styleUrls: ['./oauth-result.component.css'],
})
export class OauthResultComponent implements OnInit {
  code: string;
  state: string;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    // Get query params
    this.code = this.route.snapshot.queryParamMap.get('code');
    this.state = this.route.snapshot.queryParamMap.get('state');

    // Check query params with local storage
    if (this.state !== localStorage.getItem('state')) {
      this.RedirectToLogin('An error occurred while processing your request! Please try again later!');
    } else {
      // Call API for auth token
      this.authService
        .getAccessToken(this.code, localStorage.getItem('cv'))
        .pipe(first())
        .subscribe(
          (data) => {
            // Get curent user info
            this.userService.getUser();
            localStorage.removeItem('cv');
            localStorage.removeItem('state');

            this.router.navigate(['dashboard']);
          },
          (error) => {
            this.RedirectToLogin(error);
          }
        );
    }
  }

  // Redirect to login page
  private RedirectToLogin(error: any = null) {
    localStorage.clear();
    localStorage.setItem('error', error);
    this.router.navigate(['login']);
  }
}
