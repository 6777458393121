import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Token } from 'src/app/_models/auth/token';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentTokenSubject: BehaviorSubject<Token>;
  public curentToken: Observable<Token>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentTokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('token')));
    this.curentToken = this.currentTokenSubject.asObservable();
  }

  public get getCurrentTokenValue(): Token {
    if (!this.currentTokenSubject.value) {
      localStorage.clear();

      this.router.navigate(['login']);

      return null;
    }

    return this.currentTokenSubject.value;
  }

  getAccessToken(code: string, codeVerifier: string) {
    const body = new HttpParams()
      .set('grant_type', environment.grantType)
      .set('client_id', environment.clientId)
      .set('redirect_uri', window.location.origin + environment.authoriseResposeUri)
      .set('code', code)
      .set('code_verifier', codeVerifier);

    return this.http
      .post<Token>(environment.getAccessTokenUri, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(
        map((token) => {
          localStorage.setItem('token', JSON.stringify(token));
          this.currentTokenSubject.next(token);
          return token;
        })
      );
  }

  getRefreshedToken() {
    const body = new HttpParams()
      .set('grant_type', environment.refreshGrantType)
      .set('client_id', environment.clientId)
      .set('refresh_token', this.getCurrentTokenValue.refresh_token);
    return this.http
      .post<Token>(environment.getAccessTokenUri, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(
        map((token) => {
          localStorage.setItem('token', JSON.stringify(token));
          this.currentTokenSubject.next(token);
          return token;
        }),
        catchError(this.handleError)
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      localStorage.clear();

      this.router.navigate(['login']);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  logout() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + this.currentTokenSubject.value.access_token,
      }),
      body: {
        token_id: this.currentTokenSubject.value.access_token,
      },
    };

    localStorage.clear();

    return this.http.delete(environment.logoutUri, options).subscribe(() => {
      this.router.navigate(['login']);
    });
  }
}
