<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="200px" [(nzCollapsed)]="isCollapsed">
    <div class="sidebar-logo">
      <a routerLink="/dashboard/track-control">
        <img class="logoMini" *ngIf="isCollapsed" src="../assets/qt_logo_mini.png" alt="logo" />
        <img class="logoExtended" *ngIf="!isCollapsed" src="../assets/qt_logo.png" alt="logo" />
      </a>
    </div>
    <ul nz-menu nzMode="inline" nzTheme="dark" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item routerLink="/dashboard/track-control" routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="dashboard" nzTheme="fill" style="font-size: 24px; vertical-align: middle"></i>
        <span> Track Control</span>
      </li>
      <li *ngIf="currentUser.role != 'Viewer'" nz-menu-item routerLink="/dashboard/track-settings"
        routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="setting" nzTheme="fill" style="font-size: 24px; vertical-align: middle"></i>
        <span> Track Settings</span>
      </li>
      <li *ngIf="currentUser.role != 'Viewer'" nz-menu-item routerLink="/dashboard/history"
        routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="clock-circle" nzTheme="outline" style="font-size: 24px; vertical-align: middle"></i>
        <span> History</span>
      </li>
      <li *ngIf="currentUser.role != 'Viewer'" nz-menu-item routerLink="/dashboard/reports"
        routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="area-chart" nzTheme="outline" style="font-size: 24px; vertical-align: middle"></i>
        <span> Reports</span>
      </li>
      <li *ngIf="currentUser.role == 'Admin'" nz-menu-item routerLink="/dashboard/users"
        routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="user" nzTheme="outline" style="font-size: 24px; vertical-align: middle"></i>
        <span> Users</span>
      </li>
    </ul>
    <ul nz-menu nzMode="inline" nzTheme="dark" class="logout-menu" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item (click)="logout()">
        <i nz-icon nzType="logout" nzTheme="outline" style="font-size: 24px; vertical-align: middle"></i>
        <span> Logout</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <nz-breadcrumb [nzAutoGenerate]="true" class="breadcrumb-top"></nz-breadcrumb>
      </div>
    </nz-header>
    <nz-content class="main-body">
      <app-category-change-modal></app-category-change-modal>

      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>