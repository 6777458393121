<div class="inner-content">
  <div nz-row nzGutter="32">
    <div nz-col [nzXs]="24">
      <div nz-row nzType="flex" nzAlign="middle">
        <p class="label" nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="4">Select Session</p>
        <nz-select
          class="session-select"
          #sessionSelect
          [(ngModel)]="selectedSession"
          name="session"
          nzPlaceHolder="Session ..."
          nz-col
          [nzXs]="24"
          [nzSm]="24"
          [nzMd]="18"
          [nzLg]="18"
          [nzXl]="20"
          (ngModelChange)="selectSession($event)"
          (nzScrollToBottom)="loadMoreSessions()"
        >
          <nz-option
            nzCustomContent
            *ngFor="let session of sessions"
            [nzValue]="session.id"
            [nzLabel]="
              (session.startTime | date: dateTimeFormat) +
              ' - ' +
              (session.endTime | date: timeFormat) +
              ' ' +
              session.controller +
              ' - Category ' +
              session.category
            "
          >
            <b
              >{{ session.startTime | date: dateTimeFormat }}
              {{ session.finished ? ' - ' + (session.endTime | date: timeFormat) : '' }}
            </b>
            <span
              class="ant-tag"
              [ngClass]="
                session.category == 'C' ? 'ant-tag-green' : session.category == 'B' ? 'ant-tag-orange' : 'ant-tag-red'
              "
            >
              {{ 'Category ' + session.category }}
            </span>
            <ng-container *ngIf="session.limitsExceeded">
              <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
            </ng-container>
            <br />
            <b>Track Controller:</b> {{ session.controller }}
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <div nz-row nzType="flex" nzAlign="middle">
        <p class="label" nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="4">Select Date</p>
        <nz-range-picker
          class="date-select"
          [(ngModel)]="selectedDate"
          [nzFormat]="dateFormat"
          nz-col
          [nzStyle]="{ width: '100%' }"
          style="flex: auto"
          (ngModelChange)="selectDateRange($event)"
          [nzDisabledDate]="disabledDate"
        >
        </nz-range-picker>
        <div>
          <button nz-button nzType="primary" style="margin-left: 10px" (click)="getByDateRange()">
            <i nz-icon nzType="redo" nzTheme="outline"></i>
            Load Data
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="h75">
  <div class="flex-container">
    <div class="inner-content" style="flex: auto">
      <button
        nz-button
        nzType="primary"
        style="float: right; z-index: 99"
        (click)="saveChart()"
        [disabled]="chartEmpty"
      >
        <i nz-icon nzType="save" nzTheme="outline"></i>
        Save Chart
      </button>
      <div *ngIf="chartEmpty; else elseBlock"><nz-empty style="margin-top: 100px"></nz-empty>.</div>
      <ng-template #elseBlock>
        <div
          echarts
          (chartInit)="onChartInit($event)"
          [options]="chartOption"
          [initOpts]="initOpts"
          [merge]="updateOptions"
          [autoResize]="true"
          class="chart"
          style="height: 100%"
        ></div>
      </ng-template>
    </div>
    <div class="session-info" *ngIf="showDetails && historyBySession != null">
      <div class="inner-content">
        <p class="info-p">
          Start Time:
          <span>{{ historyBySession.StartTime | date: 'dd/MM/y HH:mm:ss' }}</span>
        </p>
        <p class="info-p">
          End Time:
          <span>{{
            historyBySession.Finished ? (historyBySession.EndTime | date: 'dd/MM/y HH:mm:ss') : 'Session in progress'
          }}</span>
        </p>
        <p class="info-p">
          Category of Use: <span>{{ historyBySession.Category }}</span>
        </p>
        <p class="info-p">
          Track Controller: <span>{{ historyBySession.Controller }}</span>
        </p>
        <p class="info-p">
          Weather Conditions:
          <span class="multiline-text">{{ historyBySession.Weather }}</span>
        </p>
        <p class="info-p">
          Vehicles:
          <span class="multiline-text">{{ historyBySession.Vehicles }}</span>
        </p>
        <p class="info-p">
          Drivers:
          <span class="multiline-text">{{ historyBySession.Drivers }}</span>
        </p>
        <p class="info-p">
          Track Purpose:
          <span class="multiline-text">{{ historyBySession.Purpose }}</span>
        </p>
        <p class="info-p">
          Limit Exceeded:
          <span>{{ historyBySession.LimitsExceeded ? 'Yes' : 'No' }}</span>
        </p>
        <p class="info-p" *ngIf="sessionCalibration != null; else noCal">
          Calibration: <span>{{ sessionCalibration.time | date: 'dd/MM/y HH:mm:ss' }}</span>
        </p>
        <ng-template #noCal>
          <p class="info-p">Calibration: <span nz-text nzType="danger">Not Calibrated</span></p>
        </ng-template>
      </div>
      <div class="inner-content" style="flex: 1">
        <p><b>Session Log</b></p>
        <cdk-virtual-scroll-viewport itemSize="73" style="height: 90%">
          <nz-list [nzDataSource]="logs" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
            <ng-template #item let-item>
              <nz-timeline-item
                class="border-bottom"
                nzColor="{{ item.Type == 'System' ? 'Red' : item.Type == 'Session' ? 'Green' : 'Blue' }}"
              >
                <p class="m-0">
                  {{ (item.Time | date: 'HH:mm:ss') + ' ' + item.Message }}
                </p>
              </nz-timeline-item>
            </ng-template>
          </nz-list>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</div>
