import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionObj } from 'src/app/_models/session/session';
import { Observable, BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { subDays } from 'date-fns';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  RoutesRecognized,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SessionService {
  fromDate = new Date();
  toDate = new Date();
  first = true;
  ContinuationToken: string = null;
  sessions: BehaviorSubject<SessionObj[]> = new BehaviorSubject([]);
  selectedSession: BehaviorSubject<string> = new BehaviorSubject(null);

  selectedDate: BehaviorSubject<Date[]> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    public datepipe: DatePipe,
    router: Router
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd && (event.url == '/dashboard/history' || event.url == '/dashboard/reports')) {
        //this.resetSessionList();
        this.getSessions();
      }
    });
  }

  ngOnInit() {
    this.getSessions();
  }

  getSelectedSession() {
    return this.selectedSession.getValue();
  }

  getSelectedSessionObservable() {
    return this.selectedSession.asObservable();
  }

  setSelectedSession(session: string) {
    this.selectedSession.next(session);
  }

  getSelectedDate() {
    return this.selectedDate.getValue();
  }

  getSelectedDateObservable() {
    return this.selectedDate.asObservable();
  }

  setSelectedDate(session: Date[]) {
    this.selectedDate.next(session);
  }

  getSessions() {
    const options: Object = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
      observe: 'response',
      responseType: 'json',
    };

    var query = '?limit=' + 10;

    if (this.ContinuationToken != null) {
      query = query + '&ContinuationToken=' + this.ContinuationToken;
    } else if (!this.first) {
      //reached end
      return;
    }

    this.first = false;

    var response = this.http.get<HttpResponse<SessionObj[]>>(
      environment.apiUri + environment.getSessionUri + query,
      options
    );
    response.subscribe((resp) => {
      this.ContinuationToken = resp.headers.get('x-continuationtoken');
      this.sessions.next([...this.sessions.getValue(), ...resp.body]);
    });
  }

  resetSessionList() {
    this.ContinuationToken = null;
    this.sessions = new BehaviorSubject([]);
    this.selectedSession = new BehaviorSubject(null);
    this.selectedDate = new BehaviorSubject(null);
    this.first = true;
  }
}
