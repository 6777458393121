/// <reference types="@types/echarts" />

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from 'src/app/_services/session/session.service';
import { SessionObj } from 'src/app/_models/session/session';
import { HistoryService } from 'src/app/_services/history/history.service';
import { HistoryBySession } from 'src/app/_models/history/history.by.session';
import { HistoryByDate } from 'src/app/_models/history/history.by.date';
// import { ChartBySession } from 'src/app/_models/history/chart.by.session';
import { SessionEvent } from 'src/app/_models/history/event';
import { NzMessageService, CandyDate } from 'ng-zorro-antd';
import Helper from 'src/app/_helpers/helper';
import { Log } from 'src/app/_models/history/log';
import { EChartOption, EChartsLoadingOption, EChartsSeriesType } from 'echarts';
// import { initOpts } from 'src/app/_models/history/initOpts';
import { differenceInCalendarDays, subMinutes } from 'date-fns';
import { DateRangePickerComponent } from 'ng-zorro-antd/date-picker/date-range-picker.component';
import { CalibrationService } from 'src/app/_services/calibrations/calibrations.service';
import { CalibrationLog } from 'src/app/_models/calibration/calibration.log';

function tooltipFormatter(params: any[]): string {
  const date = new Date(params[0].value[0]);
  let tooltip = '';
  let first = true;

  for (const item of params) {
    // tooltip += '<b>' + item.seriesName + '</b>';
    if (first) {
      tooltip += "<b><span style='float: right'>" + date.toLocaleTimeString() + '</span></b>';

      first = false;
    }
    tooltip += '<br /> ';
    if (item.value[1] != null) {
      tooltip += item.marker + '<b>' + item.seriesName + ':</b> ' + item.value[1].toFixed(1) + ' dB ';
    }
    // tooltip += '<br />';
  }
  return tooltip;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  echartsInstance: any;
  selectedSession: string;
  sessionCalibration: CalibrationLog;
  chartEmpty = true;
  isLoadingSessions = false;
  dateFormat = 'dd/MM/yyyy';
  dateTimeFormat = 'dd/MM/yyyy  HH:mm:ss';
  timeFormat = 'HH:mm:ss';
  sessions: SessionObj[];
  selectedDate: Date[] = null;
  historyBySession: HistoryBySession = null;
  historyByDate: HistoryByDate;
  chartSeries: EChartOption.SeriesLine[] = [];
  showDetails: boolean;
  showChart: boolean;
  logs: Log[];
  initOpts = {
    renderer: 'canvas',
  };

  updateOptions: any = {};

  chartOption: EChartOption = {
    //@ts-ignore: width is allowed to be a string
    legend: { left: 0, width: '90%' },
    grid: [
      {
        top: 50,
        left: 20,
        right: 30,
        height: '42%',
        containLabel: true,
      },
      {
        left: 20,
        right: 30,
        height: '42%',
        top: '51%',
        containLabel: true,
      },
    ],
    xAxis: [
      {
        gridIndex: 0,
        type: 'time',
        scale: true,
        splitLine: { show: false },
        axisLabel: {
          color: '#000',
        },
      },
      {
        gridIndex: 1,
        type: 'time',
        scale: true,
        splitLine: { show: false },
        axisLabel: {
          color: '#000',
        },
      },
    ],
    yAxis: [
      {
        gridIndex: 0,
        name: 'Level (dB)',
        nameLocation: 'middle',
        nameGap: 30,
        type: 'value',
        max: 140,
        min: (value) => {
          return Math.floor(value.min / 20) * 20;
        },
        axisTick: {
          show: false,
        },
      },
      {
        gridIndex: 1,
        name: 'Level (dB)',
        nameLocation: 'middle',
        nameGap: 30,
        type: 'value',
        max: 140,
        min: (value) => {
          return Math.floor(value.min / 20) * 20;
        },
        axisTick: {
          show: false,
        },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
      },
      {
        type: 'slider',
        start: 0,
        end: 100,
        xAxisIndex: [0, 1],
      },
    ],
    tooltip: { show: true, trigger: 'axis', formatter: tooltipFormatter },
  };

  autoResize = true;

  constructor(
    private sessionService: SessionService,
    private historyService: HistoryService,
    private calibrationService: CalibrationService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.sessionService.getSessions();


    this.sessionService.sessions.subscribe((session) =>{
      this.sessions = session;
    })

    this.sessionService.selectedSession.subscribe((x) => (this.selectedSession = x));
    this.sessionService.selectedDate.subscribe((x) => (this.selectedDate = x));

    this.selectedSession = this.sessionService.getSelectedSession();
    this.selectedDate = this.sessionService.getSelectedDate();

    if (this.selectedSession != null) {
      this.selectSession(this.selectedSession);
    } else if (this.selectedDate != null) {
      this.selectDateRange(this.selectedDate);
      this.getByDateRange();
    }
  }

  loadMoreSessions() {
    if (this.isLoadingSessions) {
      return;
    }
    this.isLoadingSessions = true;
    this.sessionService.getSessions();

    this.isLoadingSessions = false;
  }

  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, new Date()) > 0;
  };

  selectSession(sessionId) {
    this.selectedDate = null;

    if (sessionId == null) {
      Helper.CreateErrorMessage(this.message, 'Please select a session');
    } else {
      this.sessionService.setSelectedSession(sessionId);
      this.sessionService.setSelectedDate(null);

      this.showDetails = true;
      this.showSessionHistory();
    }
  }

  selectDateRange(date: Date[]) {
    this.sessionService.setSelectedSession(null);
    this.sessionService.setSelectedDate(date);
  }

  getByDateRange() {
    if (this.selectedDate == null) {
      Helper.CreateErrorMessage(this.message, 'Please select a date range');
    } else {
      this.showDetails = false;
      this.showDateHistory();
    }
  }

  showSessionHistory() {
    this.chartEmpty = true;
    this.historyService.getHistoryBySession(this.sessionService.getSelectedSession()).subscribe(
      (data) => {
        this.historyBySession = data;
        this.logs = data.Log;

        this.calibrationService.getCalibration(new Date(this.historyBySession.StartTime)).subscribe(
          (x) => {
            this.sessionCalibration = x;
          },
          (error) => {
            this.sessionCalibration = null;
          }
        );

        const updateSeries: any[] = [
          {
            name: 'Track LAFMax',
            type: 'line',
            symbol: 'none',
            sampling: 'max',
            step: 'start',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.TrackLAFMax,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Track LAFMax',
            type: 'line',
            symbol: 'none',
            sampling: 'max',
            step: 'start',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.TrackLAFMax,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            name: 'Track LAeq 5min',
            type: 'line',
            symbol: 'none',
            sampling: 'max',
            step: 'start',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.TrackLAeqT1,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Track LAeq 60min',
            type: 'line',
            symbol: 'none',
            sampling: 'max',
            step: 'start',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.TrackLAeqT2,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Track LAeq 60min',
            type: 'line',
            symbol: 'none',
            sampling: 'max',
            step: 'start',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.TrackLAeqT2,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            name: 'Community LAeq 5min',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAeqT1,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Community LAeq 5min',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAeqT1,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            name: 'Community LAeq 60min',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAeqT2,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Community LAeq 60min',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAeqT2,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            name: 'Community LAFMax Low',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAFMaxLow,
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: 'Community LAFMax High',
            type: 'line',
            symbol: 'none',
            step: 'start',
            sampling: 'max',
            showSymbol: false,
            hoverAnimation: false,
            data: data.Data.CommunityLAFMaxHigh,
            xAxisIndex: 1,
            yAxisIndex: 1,
          },
          {
            xAxisIndex: 0,
            yAxisIndex: 0,
            name: 'Community Threshold',
            type: 'line',
            symbolSize: 0,
            showSymbol: false,
            hoverAnimation: false,
            symbol: 'triangle',
            itemStyle: {
              color: '#FF0000',
            },
            lineStyle: {
              color: '#FF0000',
            },

            markLine: {
              symbol: ['none', 'none'],
              label: {
                show: false,
              },
              lineStyle: {
                type: 'dashed',
                color: '#FF0000',
              },
              data: [
                {
                  yAxis: data.CategorySettings.find((x) => x.Name === data.Category).LAFMaxLowLimit,
                },
              ],
            },
          },
          {
            xAxisIndex: 1,
            yAxisIndex: 1,
            name: 'Community Threshold',
            type: 'line',
            symbolSize: 0,
            showSymbol: false,
            hoverAnimation: false,
            symbol: 'triangle',
            itemStyle: {
              color: '#FF0000',
            },
            lineStyle: {
              color: '#FF0000',
            },

            markLine: {
              symbol: ['none', 'none'],
              label: {
                show: false,
              },
              lineStyle: {
                type: 'dashed',
                color: '#FF0000',
              },
              data: [
                {
                  yAxis: data.CategorySettings.find((x) => x.Name === data.Category).LAFMaxHighLimit,
                },
              ],
            },
          },
        ];

        this.chartOption = { ...this.chartOption, series: updateSeries };

        this.chartEmpty = false;
      },
      (error) => {
        this.chartEmpty = true;
        this.showDetails = false;
        Helper.CreateErrorMessage(this.message, 'Selected session does not contain data.');
      }
    );
  }

  showDateHistory() {
    this.chartEmpty = true;
    const startDate = new Date(this.selectedDate[0]);
    const endDate = new Date(this.selectedDate[1]);

    this.historyService.getHistoryByDate(startDate, endDate).subscribe(
      (data) => {
        this.chartSeries = [];

        const CalData = [];

        this.calibrationService.getCalibrations(startDate, endDate).subscribe(
          (caldata) => {
            caldata.forEach((element) => {
              CalData.push({ xAxis: new Date(element.time) });
            });
            const updateOptions = [
              {
                name: 'Track LAFMax',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAFMax,
                xAxisIndex: 0,
                yAxisIndex: 0,
              },
              {
                name: 'Track LAFMax',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAFMax,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Track LAeq 5min',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAeqT1,
                xAxisIndex: 0,
                yAxisIndex: 0,
              },
              {
                name: 'Track LAeq 5min',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAeqT1,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Track LAeq 60min',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAeqT2,
                xAxisIndex: 0,
                yAxisIndex: 0,
              },
              {
                name: 'Track LAeq 60min',
                type: 'line',
                symbol: 'none',
                sampling: 'max',
                step: 'start',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.TrackLAeqT2,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Community LAeq 5min',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAeqT1,
                xAxisIndex: 0,
                yAxisIndex: 0,
              },
              {
                name: 'Community LAeq 5min',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAeqT1,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Community LAeq 60min',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAeqT2,
                xAxisIndex: 0,
                yAxisIndex: 0,
              },
              {
                name: 'Community LAeq 60min',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAeqT2,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Community LAFMax Low',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAFMaxLow,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                name: 'Community LAFMax High',
                type: 'line',
                symbol: 'none',
                step: 'start',
                sampling: 'max',
                showSymbol: false,
                hoverAnimation: false,
                data: data.Data.CommunityLAFMaxHigh,
                xAxisIndex: 1,
                yAxisIndex: 1,
              },
              {
                xAxisIndex: 0,
                yAxisIndex: 0,
                name: 'Calibration',
                type: 'line',
                symbolSize: 0,
                showSymbol: false,
                hoverAnimation: false,
                symbol: 'triangle',
                animation: false,
                itemStyle: {
                  color: '#FF0000',
                },
                lineStyle: {
                  color: '#FF0000',
                },
                markLine: {
                  silent: false,
                  symbol: ['circle', 'none'],
                  lineStyle: {
                    type: 'dashed',
                    color: '#FF0000',
                  },
                  label: {
                    show: false,
                  },
                  data: CalData,
                },
              },
              {
                xAxisIndex: 1,
                yAxisIndex: 1,
                name: 'Calibration',
                type: 'line',
                symbolSize: 0,
                showSymbol: false,
                hoverAnimation: false,
                symbol: 'triangle',
                animation: false,
                itemStyle: {
                  color: '#FF0000',
                },
                lineStyle: {
                  color: '#FF0000',
                },
                markLine: {
                  silent: false,
                  symbol: ['circle', 'none'],
                  lineStyle: {
                    type: 'dashed',
                    color: '#FF0000',
                  },
                  label: {
                    show: false,
                  },
                  data: CalData,
                },
              },
            ];

            this.chartOption = { ...this.chartOption, series: updateOptions };

            this.chartEmpty = false;
          },
          (errors) => {
            Helper.CreateErrorMessage(this.message, 'Error getting cal data.');
          }
        );
      },
      (error) => {
        this.chartEmpty = true;
        Helper.CreateErrorMessage(this.message, 'Selected interval does not contain data.');
      }
    );
  }

  showCalHistory() {
    const startDate = new Date(this.selectedDate[0]);
    const endDate = new Date(this.selectedDate[1]);
  }

  saveChart() {
    const link = document.createElement('a');
    link.href = this.echartsInstance.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    link.download = 'Chart.png';
    document.body.appendChild(link);
    link.click();
  }

  onChartInit(e: any) {
    this.echartsInstance = e;
  }
}
