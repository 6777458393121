import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Connection } from 'src/app/_models/control/connection';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { AuthenticationService } from '../auth/authentication.service';
import { Weather } from 'src/app/_models/control/weather';
import { Limit } from 'src/app/_models/control/limit';
import { Error } from 'src/app/_models/control/error';
import Helper from 'src/app/_helpers/helper';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { DataChanged } from 'src/app/_models/control/data.changed';
import { Value } from 'src/app/_models/control/value';
import { ControlService } from '../control/control.service';
import { SessionInfo } from 'src/app/_models/control/session.info';
import { Subscription, interval, BehaviorSubject, Observable } from 'rxjs';
import { Calibration } from 'src/app/_models/control/calibration';
import { AlarmService } from '../alarm/alarm.service';
import { addSeconds } from 'date-fns';
import { User } from 'src/app/_models/user/user';
import { UserService } from '../user/user.service';

@Directive()
@Injectable({ providedIn: 'root' })
export class SignalRService {
  source = interval(15000);
  connection: Connection;
  hubConnection: signalR.HubConnection;
  sessionInfo: BehaviorSubject<SessionInfo> = new BehaviorSubject<SessionInfo>({
    running: false,
    paused: false,
    sessionId: '',
    exceedObject: null
  });
  weatherInfo: Weather = {} as any;
  systemStatus: BehaviorSubject<any> = new BehaviorSubject({ weather: false });

  subscription: Subscription;
  dataChanged: DataChanged = {} as any;
  retryCount = 0;
  newToken = false;
  connectionState: BehaviorSubject<HubConnectionState>;

  @Output() limitExceededEvent = new EventEmitter();
  @Output() limitOverriddenEvent = new EventEmitter();
  @Output() calibrationEvent = new EventEmitter();
  @Output() sessionStoppedEvent = new EventEmitter();
  showExceedDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showOverrideDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  exceedObject: BehaviorSubject<{ exceedValue: string; exceedOld: string; exceedNew: string }> = new BehaviorSubject({
    exceedValue: '',
    exceedOld: '',
    exceedNew: '',
  });
  overrideEndTime: BehaviorSubject<Date> = new BehaviorSubject(null);
  overrideCat: BehaviorSubject<string> = new BehaviorSubject(null);
  overrideByUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentUser: User;

  constructor(
    private authService: AuthenticationService,
    private message: NzMessageService,
    private controlService: ControlService,
    private notification: NzNotificationService,
    private alarmService: AlarmService,
    private userService: UserService
  ) {
    this.weatherInfo.windSpeed = 0;
    this.weatherInfo.windDirection = 0;
    this.connectionState = new BehaviorSubject<HubConnectionState>(HubConnectionState.Connecting);
  }

  updateConnectionState(state: HubConnectionState) {
    this.connectionState.next(state);
  }

  getSessionInfo() {
    return this.sessionInfo.asObservable();
  }

  setSessionInfo(newValue) {
    this.sessionInfo.next(newValue);
  }

  startService() {
    this.initSignalR();
  }

  private initSignalR() {

    // Set curent user info
    this.userService.getUser();

    this.userService.currentUserSubject.subscribe((x) => {
      this.currentUser = x;
    });

    this.controlService.connect().subscribe((data) => {
      this.connection = data;

      const builder = new HubConnectionBuilder();
      this.hubConnection = builder
        .withUrl(this.connection.server, {
          accessTokenFactory: () => {
            return this.authService.getCurrentTokenValue.access_token;
          },
        })
        .build();

      this.hubConnection.on('WeatherChanged', (speed, direction) => {
        this.systemStatus.next({ ...this.systemStatus, weather: true });

        this.weatherInfo.windDirection = direction;
        this.weatherInfo.windSpeed = speed;
      });

      this.hubConnection.on('LimitExceeded', (value, changeCategory: boolean, oldCategory, newCategory) => {

        if (this.sessionInfo.getValue().controller != this.currentUser.name){
          return;
        }

        this.limitExceededEvent.emit({
          value: value,
          changeCategory: changeCategory,
          oldCategory: oldCategory,
          newCategory: newCategory,
        });

        this.alarmService.startAlarm();

        if (changeCategory) {
          this.showExceedDialog.next(true);

          this.exceedObject.next({
            exceedValue: value,
            exceedOld: oldCategory,
            exceedNew: newCategory,
          });
        } else {
          //LAF breached
        }
      });

      this.hubConnection.on('LimitOverridden', (user: boolean, category) => {
        this.limitOverriddenEvent.emit({ user: user, category: category });
        this.overrideEndTime.next(addSeconds(new Date(), 90));
        this.overrideByUser.next(user);
        this.overrideCat.next(category);
        this.showOverrideDialog.next(true);
      });

      this.hubConnection.on('StatusMessage', (type, message, status) => {
        if (type === 'Weather') {
          this.systemStatus.next({ ...this.systemStatus, weather: status });
        }
        Helper.CreateErrorMessage(this.message, 'Error type: ' + type + ' Message: ' + message);
      });

      this.hubConnection.on('CalibrationComplete', (success, status, correction) => {
        this.calibrationEvent.emit(success);
      });

      this.hubConnection.on('Error', (reason) => {
        if (reason === 'LostConnection') {
          this.hubConnection.stop();
        }
      });

      this.hubConnection.on('SessionStopped', (reason) => {
        Helper.createBasicNotification(this.notification, 'Session Stopped', `Reason: ${reason == "LimitExceeded" ? "Limit Exceeded" : reason}` );
        this.showOverrideDialog.next(false);
        this.sessionStoppedEvent.emit(reason);
      });

      // Data Changed Event
      this.hubConnection.on('DataChanged', (currentTime: Date, elapsedTime, remainingTime, values: Value[]) => {
        this.dataChanged.currentTime = currentTime;
        this.dataChanged.elapsedTime = elapsedTime;
        this.dataChanged.remainingTime = remainingTime;
        this.dataChanged.values = values;
      });

      // Reconect To Server
      this.hubConnection.onclose(() => {
        this.updateConnectionState(this.hubConnection.state);
        setTimeout(() => this.startSignalR(), 10000);
      });

      this.startSignalR().catch(() => {
        setTimeout(() => this.initSignalR(), 10000);
      });
    });
  }

  overrideEnd() {
    // end session
    this.showOverrideDialog.next(false);

    this.stopSession().then((result) => {
      this.updateSessionInfo();
    });
  }

  overrideTimeout() {
    if (!this.showOverrideDialog.getValue()) {
      return;
    }

    // timeout
    this.showOverrideDialog.next(false);

    this.endOverride().then(() => {
      this.updateSessionInfo();
    });
  }

  getCancelOverrideMessage() {
    this.cancelOverride().then(() => {
      this.updateSessionInfo();
      this.showOverrideDialog.next(false);
    });
  }

  private auth() {
    this.retryCount++;
    if (this.retryCount === 10 || this.newToken) {
      this.retryCount = 0;
      this.newToken = false;
      this.controlService.connect().subscribe((data) => {
        this.connection = data;
      });
    }
    this.hubConnection
      .invoke('Authenticate', this.connection.instrument, this.connection.key)
      .then((auth: boolean) => {
        if (auth) {
          this.updateSessionInfo().then(() => this.updateSystemStatus());

          this.subscription = this.source.subscribe((val) => this.updateSessionInfo());
        } else {
          this.newToken = true;

          setTimeout(() => {
            this.auth();
          }, 5000);

          // this.hubConnection.stop();
        }
      })
      .catch((error) => {
        this.hubConnection.stop();
      });
  }

  private startSignalR() {
    this.updateConnectionState(HubConnectionState.Connecting);
    return this.hubConnection.start().then(() => {
      this.updateConnectionState(this.hubConnection.state);
      this.auth();
    });
  }

  updateSessionInfo() {
    const promise = new Promise((resolve, reject) => {
      this.hubConnection
        .invoke('GetSession')
        .then((session: SessionInfo) => {
          if (this.sessionDiff(this.sessionInfo.getValue(), session)) {
            this.setSessionInfo(session);
            if (session.exceedObject){
              this.showExceedDialog.next(true);
              this.exceedObject.next(session.exceedObject);
            }
          }
          resolve();
        })
        .catch((error) => {
          // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
          reject();
        });
    });
    return promise;
  }

  updateSystemStatus() {
    this.hubConnection.invoke('GetSystemStatus').then((status: any) => {
      this.systemStatus.next(status);
    });
  }

  sessionDiff(a: SessionInfo, b: SessionInfo) {
    return (
      a.sessionId !== b.sessionId ||
      a.running !== b.running ||
      a.paused !== b.paused ||
      a.startTime !== b.startTime ||
      a.category !== b.category ||
      a.controller !== b.controller
    );
  }

  weatherOverride(status: boolean) {
    return this.hubConnection.invoke('WeatherOverride', status).catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    });
  }

  startSession(category: string, weather: string, vehicled: string, drivers: string, purpose: string) {
    const promise = new Promise((resolve, reject) => {
      this.hubConnection
        .invoke('StartSession', category, weather, vehicled, drivers, purpose)
        .then((result: SessionInfo) => {
          this.setSessionInfo(result);
          resolve();
        })
        .catch((error) => {
          // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
          reject();
        });
    });
    return promise;
  }

  pauseResumeSession() {
    const promise = new Promise((resolve, reject) => {
      this.hubConnection
        .invoke('PauseResumeSession', !this.sessionInfo.getValue().paused)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
          reject();
        });
    });
    return promise;
  }

  
  restartSession() {
    const promise = new Promise((resolve, reject) => {
      this.hubConnection
        .invoke('RestartSession')
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
          reject();
        });
    });
    return promise;
  }

  stopSession() {
    const promise = new Promise((resolve, reject) => {
      this.hubConnection
        .invoke('StopSession')
        .then(() => {
          resolve();
        })
        .catch((error) => {
          // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
          reject();
        });
    });
    return promise;
  }

  startCalibration() {
    this.hubConnection
      .invoke('StartCalibration')
      .then(() => {})
      .catch((error) => {
        this.calibrationEvent.emit(false);
      });
  }

  getInstrumentStatus() {
    if (this.hubConnection != null) {
      this.hubConnection
        .invoke('GetInstrumentStatus')
        .then(() => {})
        .catch((error) => {
          window.console.log(error);
        });
    }
  }

  incrementCategory() {
    return this.hubConnection.invoke('IncrementCategory').catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    }).then(()=> {
      this.updateSessionInfo();
    });
  }

  overrideCategory(user: boolean) {
    return this.hubConnection.invoke('ConfirmOverride', user).catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    });
  }

  changeCategory(oldCategory: string, newCategory: string) {
    return this.hubConnection.invoke('ConfirmCategoryChange', oldCategory, newCategory).catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    });
  }

  endOverride() {
    return this.hubConnection.invoke('EndOverride').catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    });
  }

  cancelOverride() {
    return this.hubConnection.invoke('CancelOverride').catch((error) => {
      // Helper.CreateErrorMessage(this.message, 'Server error: ' + error);
    });
  }

  categoryChangeConfirm() {
    // change category
    this.showExceedDialog.next(false);
    this.alarmService.stopAlarm();

    var exceedObject = this.exceedObject.getValue();

    this.changeCategory(exceedObject.exceedOld, exceedObject.exceedNew).then(() => {
      this.updateSessionInfo();
    });
  }

  categoryChangePause() {
    // pause session
    this.showExceedDialog.next(false);
    this.alarmService.stopAlarm();
    if (!this.sessionInfo.getValue().paused){
      
      this.pauseResumeSession().then((result) => {
        this.updateSessionInfo();  
      });

    }
  }

  categoryChangeOverride() {
    // override
    this.showExceedDialog.next(false);
    this.alarmService.stopAlarm();

    this.overrideCategory(true).then(() => {
      this.updateSessionInfo();
    });
  }

  categoryChangeTimeout() {
    if (!this.showExceedDialog.getValue()) {
      return;
    }

    // timeout
    this.showExceedDialog.next(false);
    this.alarmService.stopAlarm();

    this.overrideCategory(false).then(() => {
      this.updateSessionInfo();
    });
  }
}
