<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Add User"
  nzOkText="Add User"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="addUser(addUserForm)"
>
  <p>New users must possess a MyCirrus account</p>
  <form nz-form #addUserForm="ngForm" (ngSubmit)="addUser(addUserForm)">
    <nz-form-item>
      <nz-form-label nzFor="name" [nzSpan]="4">Name</nz-form-label>
      <nz-form-control [nzSpan]="20">
        <input nz-input name="name" id="name" [(ngModel)]="addNewUser.name" placeholder="Please enter user name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="email" [nzSpan]="4">Email</nz-form-label>
      <nz-form-control [nzSpan]="20">
        <input
          nz-input
          name="email"
          id="email"
          [(ngModel)]="addNewUser.email"
          placeholder="Please enter MyCirrus Email address"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="role" [nzSpan]="4">Role</nz-form-label>
      <nz-form-control [nzSpan]="20">
        <nz-select nzShowSearch name="role" id="role" [(ngModel)]="addNewUser.role" nzPlaceHolder="Select a role">
          <nz-option nzLabel="Viewer" nzValue="Viewer"></nz-option>
          <nz-option nzLabel="Track Controller" nzValue="TC"></nz-option>
          <nz-option nzLabel="Administrator" nzValue="Admin"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="addUserForm.ngSubmit.emit()">Add User</button>
  </div>
</nz-modal>
