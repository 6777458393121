import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/_services/users/users.service';
import { User } from 'src/app/_models/user/user';
import { NgForm } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import Helper from 'src/app/_helpers/helper';
import { UserService } from 'src/app/_services/user/user.service';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  usersList: User[] = [];
  loading = true;
  addVisible = false;
  placement = 'top';
  formPlacement = 'vertical';
  editId: string | null;

  originalNewUser: User = {
    id: null,
    name: null,
    email: null,
    role: null,
    canChange: null,
  };

  addNewUser: User = { ...this.originalNewUser };

  constructor(
    private usersService: UsersService,
    private userService: UserService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.getUsersList();
  }

  getUsersList() {
    this.loading = true;
    this.usersService.getUsers().subscribe((data) => {
      this.loading = false;
      this.usersList = data;
      const curentUserId = this.userService.getCurrentUserValue.id;
      this.usersList.forEach((element) => {
        element.canChange = element.id !== curentUserId;
      });
    });
  }

  // Delete
  deleteUser(userId: string) {
    this.usersService.deleteUser(userId).subscribe(
      () => {
        Helper.CreateSuccessMessage(this.message, 'The user has been deleted');
        this.getUsersList();
      },
      (error) => {
        // Helper.CreateErrorMessage(this.message, 'Server error: ' + error.error.Message);
      }
    );
  }

  // Add
  openAddModal() {
    this.addVisible = true;
  }

  closeAddModal() {
    this.addNewUser = { ...this.originalNewUser };
    this.addVisible = false;
  }

  submitAddModal(form: NgForm) {
    this.usersService.addUser(form.value).subscribe(
      () => {
        Helper.CreateSuccessMessage(this.message, 'New user created');
        this.getUsersList();
        this.closeAddModal();
      },
      (error) => {
        // Helper.CreateErrorMessage(this.message, 'Server error: ' + error.error.Message);
      }
    );
  }

  // Edit
  modelChanged(id: string, newRole: string) {
    this.usersService.updateUser(id, newRole).subscribe(
      () => {
        Helper.CreateSuccessMessage(this.message, 'User updated');
        this.getUsersList();
      },
      (error) => {
        // Helper.CreateErrorMessage(this.message, 'Server error: ' + error.error.Message);
      }
    );
  }
}
