import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/auth/authentication.service';
import { UserService } from '../_services/user/user.service';
import { first } from 'rxjs/operators';
import { User } from '../_models/user/user';
import { SignalRService } from '../_services/signalr/signalr.service';
import { BreakpointObserverService } from '../_services/breakpoint/breakpoint-observer.service';
import { Observable } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd';
import { AlarmService } from '../_services/alarm/alarm.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  menuWidth = '200px';
  isCollapsed = false;
  currentUser: User;
  public size$: Observable<string>;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private signalRService: SignalRService,
    private _breakpointObserverService: BreakpointObserverService,
    private message: NzMessageService,
    public alarmService: AlarmService
  ) {}

  ngOnInit() {
    // Set curent user info
    this.userService.getUser();
    // this.currentUser = this.userService.getCurrentUserValue;

    this.userService.currentUserSubject.subscribe((x) => {
      this.currentUser = x;
    });

    // Start SignalRService
    this.signalRService.startService();

    this.size$ = this._breakpointObserverService.size$;
    this.size$.subscribe((value) => {
      if (value === 'sm' || value === 'xs' || value === 'md') {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
    });
  }

  // Logout function
  logout() {
    this.authService.logout();
  }
}
