<div class="inner-content">
  <p class="mb-10">Session Log</p>
  <cdk-virtual-scroll-viewport #scrollMe itemSize="75" class="note-infinite-container">
    <nz-list [nzDataSource]="notes" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
      <ng-template #item let-item>
        <nz-timeline-item
          class="border-bottom"
          nzColor="{{ item.type == 'System' ? 'Red' : item.type == 'Session' ? 'Green' : 'Blue' }}"
        >
          <p class="m-0">
            {{ (item.time | date: 'HH:mm:ss') + ' ' + item.message }}
          </p>
        </nz-timeline-item>
      </ng-template>
    </nz-list>
  </cdk-virtual-scroll-viewport>
  <div nz-row class="mt-10">
    <div nz-col>
      <form nz-form #form="ngForm" (ngSubmit)="addNote(form)">
        <nz-input-group nzSearch [nzAddOnAfter]="AddNoteButton">
          <input
            #focusMe
            ngModel
            name="message"
            type="text"
            nz-input
            placeholder="Enter Text"
            [disabled]="!signalRService.sessionInfo.getValue().sessionId"
            autofocus
          />
        </nz-input-group>
        <ng-template #AddNoteButton>
          <button
            nz-button
            nzType="primary"
            [disabled]="!signalRService.sessionInfo.getValue().sessionId || currentUser.name !== sessionInfo.controller"
            nzSearch
          >
            Add Note
          </button>
        </ng-template>
      </form>
    </div>
  </div>
</div>
