<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Start New Session"
  nzOkText="Start Session"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="startSession(startSessionForm)"
>
  <form nz-form #startSessionForm="ngForm" (ngSubmit)="startSession(startSessionForm)">
    <nz-form-item class="m-0">
      <nz-form-label nzFor="category">Category of use</nz-form-label>
      <nz-form-control>
        <nz-select
          nzShowSearch
          name="category"
          id="category"
          [(ngModel)]="newSession.category"
          nzPlaceHolder="Select category"
        >
          <nz-option *ngFor="let category of categoriesList" [nzValue]="category.name" [nzLabel]="category.name">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-0">
      <nz-form-label nzFor="weather">Weather Conditions</nz-form-label>
      <nz-form-control>
        <textarea
          maxlength="250"
          rows="2"
          (keyup)="limitTextArea($event)"
          nz-input
          name="weather"
          [(ngModel)]="newSession.weather"
          id="weather"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-0">
      <nz-form-label nzFor="vehicles">Vehicles</nz-form-label>
      <nz-form-control>
        <textarea
          maxlength="250"
          rows="2"
          (keyup)="limitTextArea($event)"
          nz-input
          name="vehicles"
          [(ngModel)]="newSession.vehicles"
          id="vehicles"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-0">
      <nz-form-label nzFor="drivers">Drivers</nz-form-label>
      <nz-form-control>
        <textarea
          maxlength="250"
          rows="2"
          (keyup)="limitTextArea($event)"
          nz-input
          name="drivers"
          [(ngModel)]="newSession.drivers"
          type="text"
          id="drivers"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="m-0">
      <nz-form-label nzFor="purpose">Track Purpose</nz-form-label>
      <nz-form-control>
        <textarea
          maxlength="250"
          rows="2"
          (keyup)="limitTextArea($event)"
          nz-input
          name="purpose"
          [(ngModel)]="newSession.purpose"
          type="text"
          id="purpose"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="startSessionForm.ngSubmit.emit()">Start Session</button>
  </div>
</nz-modal>
