<div class="inner-content">
  <p>Time & Date</p>
  <h3>{{ now | date: 'dd/MM/yyyy  HH:mm:ss' }}</h3>
  <p>Track Controller</p>
  <h3 *ngIf="signalRService.sessionInfo.getValue().controller; else emptyUserName">
    {{ signalRService.sessionInfo.getValue().controller }}
  </h3>
  <ng-template #emptyUserName>
    <h3>---</h3>
  </ng-template>
</div>
