<div style="padding: 13px" *ngIf="isVisible">
  <nz-alert nzType="info" nzMessage="Community Limits Overridden" [nzDescription]="countdown" nzShowIcon> </nz-alert>
</div>

<ng-template #countdown>
  {{
    overrideByUser
      ? 'Session will resume shortly, please wait.'
      : overrideCategory == 'A'
      ? 'Session will resume or end shortly, please wait.'
      : 'Session will resume or change category shortly, please wait.'
  }}
</ng-template>
