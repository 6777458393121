import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from 'src/app/_models/user/user';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>({
    id: null,
    name: null,
    email: null,
    role: null,
    canChange: null,
  });

  constructor(private http: HttpClient, private authService: AuthenticationService, private router: Router) {}

  public get getCurrentUserValue(): User {
    return this.currentUserSubject.getValue();
  }
  public setCurrentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  getUser() {
    this.getUserRequest().subscribe((user) => {
      this.setCurrentUserValue(user);
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        role: user.role,
      });
      return user;
    }, catchError(this.handleError));
  }

  getUserRequest() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
      }),
    };

    return this.http.get<User>(environment.apiUri + environment.getUserUri, options);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      localStorage.clear();

      this.router.navigate(['login']);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
