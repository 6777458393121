import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { OauthResultComponent } from './oauth-result/oauth-result.component';

@NgModule({
  declarations: [LoginComponent, OauthResultComponent],
  imports: [CommonModule, NgZorroAntdModule, NzLayoutModule, NzButtonModule],
  exports: [LoginComponent],
})
export class AuthModule {}
