<div class="inner-content">
  <div nz-row nzGutter="32">
    <div nz-col [nzXs]="24">
      <div nz-row nzType="flex" nzAlign="middle">
        <p class="label" nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="4">Select Session</p>
        <nz-select
          class="session-select"
          #sessionSelect
          [(ngModel)]="selectedSession"
          name="session"
          nzPlaceHolder="Session ..."
          nz-col
          [nzXs]="24"
          [nzSm]="24"
          [nzMd]="18"
          [nzLg]="18"
          [nzXl]="20"
          (ngModelChange)="selectSession($event)"
          (nzScrollToBottom)="loadMoreSessions()"
        >
          <nz-option
            nzCustomContent
            *ngFor="let session of sessions"
            [nzValue]="session.id"
            [nzLabel]="
              (session.startTime | date: dateTimeFormat) +
              ' - ' +
              (session.endTime | date: timeFormat) +
              ' ' +
              session.controller +
              ' - Category ' +
              session.category
            "
          >
            <b
              >{{ session.startTime | date: dateTimeFormat }}
              {{ session.finished ? ' - ' + (session.endTime | date: timeFormat) : '' }}
            </b>
            <span
              class="ant-tag"
              [ngClass]="
                session.category == 'C' ? 'ant-tag-green' : session.category == 'B' ? 'ant-tag-orange' : 'ant-tag-red'
              "
            >
              {{ 'Category ' + session.category }}
            </span>
            <ng-container *ngIf="session.limitsExceeded">
              <i nz-icon type="exclamation-circle" nzTheme="outline"></i>
            </ng-container>
            <br />
            <b>Track Controller:</b> {{ session.controller }}
          </nz-option>
          <nz-option *ngIf="isLoadingSessions" nzDisabled nzCustomContent>
            <i nz-icon nzType="isLoadingSessions" class="loading-icon"></i> Loading...
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <div nz-row nzType="flex" nzAlign="middle">
        <p class="label" nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="4">Select Date</p>
        <nz-range-picker
          class="date-select"
          [(ngModel)]="selectedDate"
          [nzFormat]="dateFormat"
          nz-col
          [nzXs]="24"
          [nzSm]="24"
          [nzMd]="18"
          [nzLg]="18"
          [nzXl]="20"
          [nzStyle]="{ width: '100%' }"
          (ngModelChange)="selectDateRange($event)"
        >
        </nz-range-picker>
      </div>
    </div>
  </div>
</div>

<div nz-row>
  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12">
    <div class="inner-content">
      <h2>Summary Report</h2>
      <div nz-row nzType="flex" nzJustify="end" class="mr-top-35">
        <div nz-col>
          <button nz-button nzType="primary" (click)="getReport('summary')">
            <i nz-icon nzType="redo" nzTheme="outline"></i>Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
