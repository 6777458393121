<p>Wind Speed</p>

<nz-progress
  style="margin-left: 90px"
  [nzPercent]="signalRService.weatherInfo.windSpeed * 10 >= 100 ? 100 : signalRService.weatherInfo.windSpeed * 10"
  nzType="dashboard"
  [nzFormat]="format"
  nzGapPosition="bottom"
  nzGapDegree="120"
  [nzStrokeColor]="{ '0%': 'red', '50%': 'orange', '100%': 'green' }"
></nz-progress>
