import { Component, OnInit } from '@angular/core';
import { SessionObj } from 'src/app/_models/session/session';
import { SessionService } from 'src/app/_services/session/session.service';
import { NzMessageService } from 'ng-zorro-antd';
import Helper from 'src/app/_helpers/helper';
import { ReportService } from 'src/app/_services/reports/report.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  dateFormat = 'dd/MM/yyyy';
  dateTimeFormat = 'dd/MM/yyyy  HH:mm:ss';
  timeFormat = 'HH:mm:ss';

  sessions: SessionObj[];
  isLoadingSessions = false;
  selectedSession: string = null;
  selectedDate: Date[] = null;
  selectedType: string = null;

  constructor(
    private sessionService: SessionService,
    private message: NzMessageService,
    private reportService: ReportService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.sessionService.getSessions();

    this.sessionService.sessions.subscribe((session) => {
      this.sessions = session;
    });

    this.sessionService.selectedSession.subscribe((x) => (this.selectedSession = x));
    this.sessionService.selectedDate.subscribe((x) => (this.selectedDate = x));

    this.selectedSession = this.sessionService.getSelectedSession();
    this.selectedDate = this.sessionService.getSelectedDate();
  }

  loadMoreSessions() {
    if (this.isLoadingSessions) {
      return;
    }

    this.isLoadingSessions = true;
    this.sessionService.getSessions();

    this.isLoadingSessions = false;
  }

  selectSession(session: string) {
    this.sessionService.setSelectedSession(session);
    this.sessionService.setSelectedDate(null);
  }

  selectDateRange(date) {
    this.sessionService.setSelectedDate(date);
    this.sessionService.setSelectedSession(null);
  }

  getReport(type: string) {
    if (this.selectedDate == null && this.selectedSession == null) {
      Helper.CreateErrorMessage(this.message, 'Please select a Session or Date range');
    } else if (this.selectedDate == null) {
      this.showSessionReport(type);
    } else {
      this.showDateReport(type);
    }
  }

  showSessionReport(type: string) {
    this.reportService.getReportBySession(this.selectedSession).subscribe(
      (resp) => {
        const body = resp.body as { url: string };
        window.location.href = body.url;
      },
      (error) => {
        // Helper.CreateErrorMessage(this.message, 'Server error: ' + JSON.stringify(error.message));
      }
    );
  }

  showDateReport(type: string) {
    const startDate = this.datepipe.transform(new Date(this.selectedDate[0]), 'yyyyMMdd');
    const endDate = this.datepipe.transform(new Date(this.selectedDate[1]), 'yyyyMMdd');

    this.reportService.getReportByDate(startDate, endDate).subscribe(
      (resp) => {
        const body = resp.body as { url: string };
        window.location.href = body.url;
      },
      (error) => {
        // Helper.CreateErrorMessage(this.message, 'Server error: ' + JSON.stringify(error.message));
      }
    );
  }
}
