import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getReportBySession(sessionId: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    return this.http.get(environment.apiUri + environment.getReportBSUri + 'basic/' + sessionId + '?format=xlsx', {
      headers,
      responseType: 'json',
      observe: 'response',
    });
  }

  getReportByDate(from: string, to: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.getCurrentTokenValue.access_token,
    });

    return this.http.get(
      environment.apiUri + environment.getReportBDUri + 'basic/time?' + 'from=' + from + '&to=' + to + '&format=xlsx',
      { headers, responseType: 'json', observe: 'response' }
    );
  }
}
