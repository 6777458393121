import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'src/app/_models/settings/category';
import { SettingsService } from 'src/app/_services/settings/settings.service';
import { SignalRService } from 'src/app/_services/signalr/signalr.service';

@Component({
  selector: 'app-values',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.css'],
})
export class ValuesComponent implements OnInit {
  @Input() catergory: string;
  constructor(public signalRService: SignalRService, public settingsService: SettingsService) {}

  ngOnInit() {}
}
