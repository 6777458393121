<nz-modal [(nzVisible)]="isVisible" nzTitle="Limit Exceeded" nzOkText="Confirm Change" (nzOnCancel)="pause()"
  (nzOnOk)="confirm()" [nzMaskClosable]="false" [nzClosable]="false">
  <p>
    {{
    exceedObject.exceedValue == 'LAeqT1'
    ? 'LAeq, 5min'
    : exceedObject.exceedValue == 'LAeqT2'
    ? 'LAeq, 60min'
    : exceedObject.exceedValue
    }}
    Community Limit Exceeded
  </p>
  <p *ngIf="exceedObject.exceedNew != ''">
    Confirm change to Category {{ exceedObject.exceedNew }} or pause the session.
  </p>
  <p *ngIf="exceedObject.exceedNew == ''">No higher categories are available. Please pause then end the session.</p>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="pause()">Pause Session</button>
    <button nz-button nzType="primary" (click)="confirm()" [disabled]="exceedObject.exceedNew == ''">
      Confirm Change
    </button>
  </div>
</nz-modal>