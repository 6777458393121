import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SettingsService } from 'src/app/_services/settings/settings.service';
import { Category } from 'src/app/_models/settings/category';
import { NgForm } from '@angular/forms';
import { NewSession } from 'src/app/_models/session/new.session';
import { UserService } from 'src/app/_services/user/user.service';

@Component({
  selector: 'app-session-start-modal',
  templateUrl: './session-start-modal.component.html',
  styleUrls: ['./session-start-modal.component.css'],
})
export class SessionStartModalComponent implements OnInit {
  @Input()
  isVisible: boolean;
  @Output() startSessionEmmiter = new EventEmitter<any>();
  categoriesList: Category[];

  originalNewSession: NewSession = {
    category: 'C',
    userName: null,
    weather: null,
    vehicles: null,
    drivers: null,
    purpose: null,
  };

  newSession: NewSession;

  @Output() messageCloseToEmit = new EventEmitter();

  constructor(private settingsService: SettingsService, private userService: UserService) {}

  ngOnInit() {
    this.originalNewSession.userName = this.userService.getCurrentUserValue.name;
    this.newSession = { ...this.originalNewSession };
    this.isVisible = false;

    this.userService.currentUserSubject.subscribe((x) => {
      this.originalNewSession.userName = x.name;
    });

    this.settingsService.getCategoryList().subscribe((data) => {
      this.categoriesList = data;
    });
  }

  startSession(form: NgForm): void {
    this.startSessionEmmiter.emit(form.value);
    this.newSession = { ...this.originalNewSession };
  }

  handleCancel(): void {
    this.newSession = { ...this.originalNewSession };
    this.messageCloseToEmit.emit();
  }

  limitTextArea(evt: KeyboardEvent) {
    const textarea = evt.target as any;

    const newContent: string = (textarea.value as string)
      .split('\n')
      .filter((val, row) => row < 5)
      .join('\n');

    textarea.value = newContent;
  }
}
