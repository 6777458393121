<nz-spin [nzSpinning]="isError" [nzSize]="'large'" [nzTip]="errorMessage">
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
      <app-session-now></app-session-now>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
      <app-category></app-category>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
      <app-session-time></app-session-time>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
      <app-session-control
        (messageRestartToEmit)="getRestartMessage()"
        (messageStartToEmit)="getStartMessage()"
        (messagePauseToEmit)="getPauseMessage()"
        (messageStopToEmit)="getStopMessage()"
        (messageCancelOverrideToEmit)="this.signalRService.getCancelOverrideMessage()"
      >
      </app-session-control>
    </div>
  </div>
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
      <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <app-values></app-values>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <nz-spin [nzSpinning]="!systemStatus.weather" nzTip="Weather Unavaliable">
            <div nz-row class="inner-content">
              <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                <app-wind></app-wind>
              </div>
              <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
                <app-wind-direction [windDirection]="signalRService.weatherInfo.windDirection"></app-wind-direction>
              </div>
            </div>
          </nz-spin>
        </div>
      </div>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
      <app-override></app-override>

      <app-notes [notes]="logs" (addNoteEmmiter)="getNewNote($event)"></app-notes>
    </div>
  </div>
</nz-spin>

<app-session-start-modal
  [isVisible]="startNewSession"
  (messageCloseToEmit)="closeNewSession()"
  (startSessionEmmiter)="getStartMessageValue($event)"
>
</app-session-start-modal>